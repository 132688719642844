import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { postMethod } from "services/apiServices";
import url from "services/urls.json";
import { toast } from "react-toastify";
import TabSwitch from "components/TabSwitch/TabSwitch";
import { Box } from "@mui/material";
import Ptext from "components/Ptext/Ptext";
import H1text from "components/H1text/H1text";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {CircularProgress } from "@mui/material";

const Input = styled.input`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height:48px;

  ::placeholder {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
`;

const ForgotPassword = () => {

    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(true)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [loader, setLoader] = useState(false);

    

    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value)
    }
    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value)
    }

    const getFormData = (e) => {
        e.preventDefault()
        forgotPassword(email)
    }

    const forgotPassword = async (email) => {
        setLoader(true)
        let body;
        if(switchTab==1){

            body = {
                "email": email
            }
            await postMethod(url.forgotPassword, body).then((response) => {
                if (response?.status === 200 || response?.status === 201) {
                    toast.success(response.data.response);
                    navigate('/success/mail')
                    setLoader(false)
                }
                else {
                    toast.error(response.data.error)
                    setLoader(false)
                }
            })
        }else{
            body = {
                "phoneNumber": phoneNumber
            }
            navigate("/otp")
        }
        
    }

    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email && !emailRegex.test(email)) {
            setValidEmail(false);
        } else {
            setValidEmail(true);
        }
    }, [email]);
    useEffect(() => {
        const phoneNumberRegex = /^\d{10}$/;
        if (phoneNumber && !phoneNumberRegex.test(email)) {
            setValidPhoneNumber(false);
        } else {
            setValidPhoneNumber(true);
        }
    }, [phoneNumber]);

    const [switchTab, setSwitchTab] = useState(1)
    const tranferData = (value)=>{
        setSwitchTab(value)
    }
    const tabs = [
        {
            id:1,
            type:'Email'
        },
        {
            id:2,
            type:'Phone Number'
        },
    ]

    return (
        <Box style={{width:'100%',height:'62vh',minHeight:'fit-content', display:'flex', alignItems:'center', justifyContent:'center' }}>
            <Container style={{ width: '392px', padding:'0', padding:'16px'}}>
                <Box style={{ textAlign: 'center' }}>
                    <H1text size='36' lineHeight='34' fontWeight='600' marginBottom="34" style={{marginTop:'184px'}}>Restore Password</H1text>
                    {/* <div className="switch d-flex justify-content-center">
                        <TabSwitch tabs={tabs} tranferData={tranferData}/>
                    </div> */}
                    <div >
                        {
                            switchTab==1?
                        <Ptext size='16' lineHeight='24' fontWeight='500' marginTop="32">Enter your email address, we will send you an email with a link to reset your password</Ptext>
                        :
                        <Ptext size='16' lineHeight='24' fontWeight='500' marginTop="32">Enter your phone number, we will send you confirmation code  to reset your password</Ptext>
                        }
                    </div>
                </Box>
                { switchTab==1?
                <Box marginTop={'32px'}>  <form onSubmit={getFormData}>
                        <Input type='email' placeholder={'Email'} name='email' autoFocus value={email} className="form-control" onChange={handleChange} required />
                        {!validEmail && <p className='text-danger pt-2  text-center'>Please enter a valid email address.</p>}
                    <button type='submit' className='btn btn-dark btn-lg submit w-100 d-flex justify-content-center align-items-center' style={{position:'relative',marginTop:'32px',height:'48px',marginBottom:'184px'}} > 
                    <Ptext size='16' lineHeight='24' fontWeight='600' marginTop="0">Continue</Ptext>
                    {loader&&<CircularProgress style={{position:'absolute',right:'20px', width: '20px', height: '20px', cursor: 'pointer', color:'#ffffff', marginLeft:'20px' }} />}
                    </button>
                </form></Box>
                :
                <Box marginTop={'32px'} >  <form onSubmit={getFormData} >
                        <Input maxLength={10} type='text' placeholder={'Phone Number'} name='phoneNumber' autoFocus value={phoneNumber} className="form-control" onChange={handlePhoneNumberChange} required />
                        {!validPhoneNumber && <p className='text-danger pt-2  text-center'>Please enter a valid phone number.</p>}
                        <button type='submit' className='btn btn-dark btn-lg submit w-100 d-flex justify-content-center align-items-center' style={{position:'relative',marginTop:'32px',height:'48px',marginBottom:'184px'}} > 
                    <Ptext size='16' lineHeight='24' fontWeight='600' marginTop="0">Continue</Ptext>
                    {loader&&<CircularProgress style={{position:'absolute',right:'20px', width: '20px', height: '20px', cursor: 'pointer', color:'#ffffff', marginLeft:'20px' }} />}
                         </button>
                </form></Box>}
            </Container>
        </Box>
    )
}
export default ForgotPassword