
import { EDIT_ADDRESS,EDIT_ADDRESS_ERROR,EDIT_ADDRESS_SUCCESS } from "./constant";

const editAddressReducer=(state={},action)=>{

    switch(action.type){
        case EDIT_ADDRESS:
            return {
                ...state,
                loading:true,
                error:''
            }
            case EDIT_ADDRESS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload,
                type:EDIT_ADDRESS_ERROR
            }
            case EDIT_ADDRESS_SUCCESS:
            return {
                ...state,
                loading:false,
                error:'',
                payload:action.payload,
                type:EDIT_ADDRESS_SUCCESS
            }
            default:
                return state;
    }
}
export default editAddressReducer;