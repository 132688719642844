import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, FloatingLabel, Form } from "react-bootstrap";
import 'pages/checkout/checkout.scss';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethodWithToken, stripePostMethod } from 'services/apiServices';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AppleIcon from '@mui/icons-material/Apple';
import { useDispatch, useSelector } from "react-redux";
import { COUNTRIES } from "store/Countries/constant";
import countriesCallApi from "store/Countries/action";
import userReducer from "store/user/reducer";
import { USER } from "store/user/constant";
import userCallApi from "store/user/action";
import checkoutCallApi from "store/checkout/action";
import { CHECKOUT } from "store/checkout/constant";
import { commonCallApi, isDrawer } from "store/common/action";
import { COMMON_DETAILS, IS_DRAWER } from "store/common/constant";
import { useNavigate } from "react-router-dom";
// import PhoneInput from 'react-phone-input-2';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast } from "react-toastify";
import Card from 'pages/checkout/card/card';
import EditIcon from '@mui/icons-material/Edit';
import {
    Elements, useElements, useStripe, CardNumberElement,
    CardCvcElement, CardExpiryElement, CardElement, PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import paymentIntentCallAPI from "store/paymentIntent/action";
import { PAYMENT_INTENT } from "store/paymentIntent/constant";
import paymentIntentReducer from "store/paymentIntent/reducer";
import { Box, CircularProgress } from "@mui/material";
import Ptext from "components/Ptext/Ptext";
// import { useHistory } from 'react-router-dom'; 
import appleBrand from 'assets/images/appleBrand.png';
import BasicButton from "components/Buttons/BasicButton/BasicButton";
import BasicInput from "components/Inputs/BasicInput/BasicInput";
import ApplePayComponent from "components/ApplePayComponent";
import userAddressCallApi from "store/userAddress/action";
import { USER_ADDRESS } from "store/userAddress/constant";
const stripePromise = loadStripe('pk_test_51QQdPXCsM3zEzYYT9OAQZ8ZHp216EDP8oekV9cFWkD3Eni5gpy7qzBJBFNSNO4GC4ecD4R3aEsA8LiCwhaG5DhDo00Y3iHcSeI');


const Checkout = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const countryData = useSelector(state => state?.countriesReducer?.payload?.data);
    const userGmail = useSelector(state => state?.userReducer?.payload?.data?.email)

    const [submitLoader, setSubmitLoader] = useState(false);

    const basket = useSelector(state => state?.cartReducer?.payload?.data);
    const [expanded, setExpanded] = useState(false);
    const [country, setCountry] = useState([]);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('');
    const [countryUrl, setCountryUrl] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [postCode, setPostCode] = useState('');
    const [title, setTitle] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [cardDigit, setCardDigit] = useState('')
    const [defaultCard, setDefaultCard] = useState('')
    const [captureId, setCaptureId] = useState('')

    const titles = [
        { title: 'Mr' }, { title: 'Mrs' }, { title: 'Miss' }, { title: 'Ms' }, { title: 'Dr' }]
    const handleChange = (id) => (event, isExpanded) => {
        setExpanded(isExpanded ? id : false);
    };
    const [id, setId] = useState(1);


    const [selectedAddress, setSelectedAddress] = useState({})
    const userAddressData = useSelector((state) => state?.userAddressReducer?.payload?.data);

    useEffect(() => {
        getUserAddress();
    }, []);

    useEffect(() => {
        for (let i = 0; i < userAddressData?.length; i++) {
            if (userAddressData[i]?.is_default_for_shipping) {
                setSelectedAddress(userAddressData[i]);
                break; // Stop the loop once the default address is found
            }
        }
    }, [userAddressData]);

    const getUserAddress = () => {

        dispatch(userAddressCallApi(USER_ADDRESS, {}, {
            run: true,
            handleError: (res) => { toast.error(res?.status + ' ' + res?.statusText); },
            handleFunction: (res) => { }
        }))
    }

    const orderNow = (e) => {
        e.preventDefault()
        handleCheckout()

    }
    const handleCountries = () => {
        dispatch(countriesCallApi(COUNTRIES, {}, { run: true, handleFunction: (res) => handleCountriesSuccess(res) }))
    }
    const handleCountriesSuccess = (res) => {
        setCountry(res?.data)
    }
    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    }
    const handleLastName = (e) => {
        setLastName(e.target.value)
    }
    const handlePhone = (value) => {
        setPhone(value)
    }
    const handlePostCode = (e) => {
        setPostCode(e.target.value)
    }
    const handleState = (e) => {
        setState(e.target.value)
    }
    const handleTitle = (e) => {
        setTitle(e.target.value)
    }
    const handleAddress = (e) => {
        setAddress(e.target.value)

    }
    const handleCountry = (e) => {
        setCountryUrl(e.target.value)
        const selectedCountryData = country.find(data => data.url === e.target.value);
        setCountryCode(selectedCountryData?.iso_3166_1_a3.substring(0, 2))

    }

    // const handlePaymentMethodCreated = async (paymentMethodId, customerId) => {
    //     const secretKey = 'sk_test_51NiXijSGks82zzGkgFRQCXbhU9IEGBPo5VcIGJj9UuC9U9muXv1pLwZA8iGn0LrY8NiJULToMdUlZOkPA3qtpPwg00F6TULlzv'; // Replace with your actual secret key

    //     // Attach payment method to customer
    //     const attachUrl = `https://api.stripe.com/v1/payment_methods/${paymentMethodId}/attach`;

    //     const attachOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //             'Authorization': `Bearer ${secretKey}`,
    //         },
    //         body: new URLSearchParams({
    //             customer: customerId
    //         }).toString()
    //     };

    //     try {
    //         const attachResponse = await fetch(attachUrl, attachOptions);
    //         if (!attachResponse.ok) {
    //             const attachErrorData = await attachResponse.json();
    //             console.error('Error attaching payment method:', attachErrorData);
    //             return;
    //         }

    //         const attachData = await attachResponse.json();
    //         console.log('Payment method attached successfully', attachData);

    //         // Set the payment method as default
    //         const updateCustomerUrl = `https://api.stripe.com/v1/customers/${customerId}`;

    //         const updateOptions = {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //                 'Authorization': `Bearer ${secretKey}`,
    //             },
    //             body: new URLSearchParams({
    //                 'invoice_settings[default_payment_method]': paymentMethodId
    //             }).toString()
    //         };

    //         const updateResponse = await fetch(updateCustomerUrl, updateOptions);
    //         if (updateResponse.ok) {
    //             // const updateData = await updateResponse.json();

    //             dispatch(paymentIntentCallAPI(PAYMENT_INTENT, {}, {
    //                 toast: true,
    //                 handleToast: (res) => toast.success("Payment Intent Created"),
    //                 run:true,
    //                 handleFunction:(res)=>handleConfirm(res,paymentMethodId)
    //             }))

    //             const retriveCard = `https://api.stripe.com/v1/customers/${customerId}/payment_methods/${paymentMethodId}`
    //             const retriveCardOptions = {
    //                 method: 'GET',
    //                 headers: {
    //                     'Content-Type': 'application/x-www-form-urlencoded',
    //                     'Authorization': `Bearer ${secretKey}`,
    //                 },


    //             };



    //             const retriveCardResponse = await fetch(retriveCard, retriveCardOptions);
    //             if (retriveCardResponse.ok) {
    //                 const retriveCardData = await retriveCardResponse.json()

    //                 setCardDigit(retriveCardData?.card?.last4)
    //             }
    //         } else {
    //             const updateErrorData = await updateResponse.json();
    //             console.error('Error updating customer with default payment method:', updateErrorData);
    //         }

    //     } catch (error) {
    //         console.error('Network error:', error);
    //     }
    // };

    const [isProcessing, setIsProcessing] = useState(false);

    const customerId = useSelector(state => state?.userReducer?.payload?.data?.stripe_ID);
    const email = useSelector(state => state?.userReducer?.payload?.data?.email);

    const handleToken = async (event) => {
        if ( Object.keys(selectedAddress).length === 0) {
            toast.error("Please add an address!");
        } else {
            setSubmitLoader(true);
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            setIsProcessing(true);


            const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement));

            setIsProcessing(false);

            if (error) {
                toast.error(error.message);
                setSubmitLoader(false);
            } else if (token) {
                await handlePaymentMethodCreated(token?.id, customerId, token?.card?.id);

            }
        }
    };
    // const [clientSecret, setClientSecret] = useState();
    // const  getKeyForApplePay = async ()=>{
    //     dispatch(paymentIntentCallAPI(PAYMENT_INTENT, {}, {
    //         run: true,
    //         handleFunction: (res) => setClientSecret( res?.data?.stripe_ref?.client_secret)

    //     }))
    // }

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    let cardFontSize = '';
    if (screenSize.width <= 1100) {
        cardFontSize = "12px";
    } else if (screenSize.width <= 1500) {
        cardFontSize = "14px";
    } else {
        cardFontSize = "14px";
    }


    const handlePaymentMethodCreated = async (cardId, customerId, cardID) => {

        const secretKey = 'sk_test_51QQdPXCsM3zEzYYTdiRp1A07e8l5mWcIAExM7rH0H0GcgjxgBMKSMJ4Yf9n3fMq7ekTosodp7jXWrHr5UdzGnZDC003YLZ1h9M';
        const createCard = `https://api.stripe.com/v1/customers/${customerId}/sources`;

        const attachOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${secretKey}`,
            },
            body: new URLSearchParams({ source: cardId }),
        };

        try {
            const attachResponse = await fetch(createCard, attachOptions);

            if (!attachResponse.ok) {
                const attachErrorData = await attachResponse.json();
                return;
            }
            else {
                const attachData = await attachResponse.json();
                dispatch(paymentIntentCallAPI(PAYMENT_INTENT, {}, {
                    run: true,
                    handleFunction: (res) => handleConfirm(res, secretKey, cardID),
                    handleError:(res)=>{ toast.error(res?.statusText); setSubmitLoader(false);}
                }))

            }


        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleConfirm = async (res, key, cardID) => {
        const id = res?.data?.stripe_ref?.id;
        setCaptureId(res?.data?.stripe_ref?.id)

        const confirmAPI = `https://api.stripe.com/v1/payment_intents/${id}/confirm?return_url=https://docs.stripe.com/api/payment_methods/update`;

        const attachOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${key}`,
            },
            body: new URLSearchParams({ payment_method: cardID }),
        };
        try {
            const attachResponse = await fetch(confirmAPI, attachOptions);

            if (!attachResponse.ok) {
                const attachErrorData = await attachResponse.json();
                return;
            }
            else {
                const attachData = await attachResponse.json();
                // handleSubmit(attachData?.client_secret)
                // setClientSecret(attachData?.client_secret)
                handleCheckout();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }



    const handleSubmit = async (clientSecret) => {
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: firstName,
                    address: {
                        line1: address,
                        city: 'City',
                        state: state,
                        postal_code: postCode,
                        country: 'IN'
                    }
                }
            }
        },

        );
        if (error) {
            // 'Payment failed:', error
            setSubmitLoader(false);

        } else if (paymentIntent.status === 'requires_capture') {
            handleCheckout();

        } else if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_source_action') {

        //    'Additional action required'

        } else {
        //    'Payment status:', paymentIntent.status

        }

    }

    // const handleConfirm=async(res,paymentMethodId)=>{
    //     const { error: confirmError } = await stripe.confirmCardPayment(res?.payload?.data?.stripe_ref?.id, {
    //         payment_method: paymentMethodId,
    //     });
    //     if (confirmError) {
    //         console.error('Payment confirmation error:', confirmError);
    //     } else {
    //         console.log('Payment successful!');

    //     }
    // }

    const handleCheckout = async () => {
        const amount = localStorage.getItem('totalAmount');
        let body = {
            "basket": basket[0]?.basket,
            "guest_email": userGmail,
            "total": Number(localStorage.getItem('totalAmount')),
            "shipping_charge": {
                "currency": basket?.[0]?.price_currency,
                "excl_tax": "0.0",
                "tax": "0.0"
            },
            "shipping_method_code": "free-shipping",
            "shipping_address_id":selectedAddress?.id,
            "billing_address_id":selectedAddress?.id
            // "shipping_address": {
            //     "country": selectedAddress?.country,
            //     "first_name": selectedAddress?.firstName,
            //     "last_name": selectedAddress?.lastName,
            //     "line1": selectedAddress?.line1,
            //     "line2": "",
            //     "line3": "",
            //     "line4": "",
            //     "notes": `Hi ${selectedAddress?.firstName} it's your checkout note information `,
            //     "phone_number": selectedAddress?.phone_number,
            //     "postcode": selectedAddress?.postcode,
            //     "state": selectedAddress?.state,
            //     "title": selectedAddress?.title,
            //     "Shipping_address_id":selectedAddress?.id
            // },
            // "billing_address": {
            //     "country": selectedAddress?.country,
            //     "first_name": selectedAddress?.firstName,
            //     "last_name": selectedAddress?.lastName,
            //     "line1": selectedAddress?.line1,
            //     "line2": "",
            //     "line3": "",
            //     "line4": "",
            //     "notes": `Hi ${selectedAddress?.firstName} it's your checkout note information `,
            //     "phone_number": selectedAddress?.phone_number,
            //     "postcode": selectedAddress?.postcode,
            //     "state": selectedAddress?.state,
            //     "title": selectedAddress?.title,
            //     "Billing_address_id":selectedAddress?.id
            // }
        }
        dispatch(checkoutCallApi(CHECKOUT, body, {
            run: true,
            handleFunction: (res) => handleSuccess(res),
            handleError: (res) => {
                if (res.status == "406") {
                }
                // if (userMe?.is_anonymous) {
                //     localStorage.removeItem("token");
                // }
                toast.error(res?.data?.shipping_address?.phone_number[0]);
                setSubmitLoader(false);
                // setIsModalActive2(false);
                // console.log(res?.data?.shipping_address?.phone_number);

            }
        }))
    }

    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, {},))
        toast.success("Payment successful! Thank you for your purchase.")
        setSubmitLoader(false);
        navigate('/')
        dispatch(isDrawer(IS_DRAWER, false))

    }

    const handleCardRetrival = async (customerId) => {
        const retriveCard = `https://api.stripe.com/v1/payment_methods?customer=${customerId}&type=card`;
        const secretKey = 'sk_test_51QQdPXCsM3zEzYYTdiRp1A07e8l5mWcIAExM7rH0H0GcgjxgBMKSMJ4Yf9n3fMq7ekTosodp7jXWrHr5UdzGnZDC003YLZ1h9M';

        const attachOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${secretKey}`,
            },

        };

        try {
            const retriveResponse = await fetch(retriveCard, attachOptions);

            if (retriveResponse.ok) {
                const retriveResponseData = await retriveResponse.json();
                setDefaultCard(retriveResponseData?.data[0]?.card?.last4)

            }
            else {
                // "error"

            }


        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        handleCountries()
        handleCardRetrival(customerId)
    }, [])


    // apple pay 
    // const [paymentRequest, setPaymentRequest] = useState(null);

    // useEffect(() => {
    //   if (stripe) {
    //     const pr = stripe.paymentRequest({
    //       country: 'US',
    //       currency: 'usd',
    //       total: {
    //         label: 'Demo total',
    //         amount: 5000, // Amount in cents
    //       },
    //       requestPayerName: true,
    //       requestPayerEmail: true,
    //     });

    //     pr.canMakePayment().then(result => {
    //     //   if (result && result.applePay) {
    //     //     setPaymentRequest(pr);
    //     //   }
    //       if (result ) {
    //         setPaymentRequest(pr);
    //       } else {
    //         console.log("Apple Pay not available.");
    //       }
    //     });
    //   }
    // }, [stripe]);
    // const step = 1
    const [step, setStep] = useState(1)
    const handlePaymentNext = (step) => {
        // if (!country?.length || !firstName?.length || !lastName?.length ||
        //     !address?.length || !phone?.length ||
        //     !postCode?.length || !state?.length || !city?.length || !countryCode?.length) {
        if ( Object.keys(selectedAddress).length === 0) {
            toast.error("Please add an address!");
        } else {
            setStep(step);
        }

    }

    const [selectedCountry, setSelectedCountry] = useState('');

    const handleCountry3 = (event) => {
        setSelectedCountry(event.target.value);
    };

    return (
        <div style={{ fontFamily: "Manrope, sans-serif", marginTop: '24px' }} className="checkout mb-5">
            <div>
                <Ptext size='16' lineHeight='24' fontWeight='600' marginTop="0">Your data</Ptext>
                <div className="payment" style={{ marginTop: '16px' }}>


                    {step == 2 && <Accordion expanded={expanded === 0} onChange={handleChange(0)} style={{ border: '0.3px solid #e9e9e9' }}>
                        <AccordionSummary style={{ borderBottom: '0.3px solid #e9e9e9', height: '60px' }}
                            expandIcon={
                                expanded ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography style={{ fontWeight: '500' }}>
                                <span style={{ fontSize: '12px' }}>Payment</span>
                                <br /> <span> VISA 
                                     </span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Typography>
                                <Container fluid>
                                    <form>
                                        <Row>
                                            <Col md={12} className="mt-3">

                                                <CardNumberElement className="form-control"
                                                    options={{
                                                        style: {
                                                            base: {
                                                                fontSize: cardFontSize,
                                                                fontFamily: "Manrope, sans-serif",
                                                                color: "#808080",
                                                                fontWeight: "400",
                                                                padding: '10px'
                                                            },
                                                            invalid: {
                                                                color: "red",
                                                            },
                                                        },
                                                        placeholder: "Card Number",
                                                    }}

                                                />
                                            </Col>
                                            <Col md={6} className="mt-3">

                                                <CardExpiryElement className="form-control"
                                                    options={{
                                                        style: {
                                                            base: {
                                                                fontSize: cardFontSize,
                                                                fontFamily: "Manrope, sans-serif",
                                                                color: "#808080",
                                                                fontWeight: "400",
                                                                padding: '10px'
                                                            },
                                                            invalid: {
                                                                color: "red",
                                                            },
                                                        },
                                                        placeholder: "MM/YY",
                                                    }}

                                                />
                                            </Col>
                                            <Col md={6} className="mt-3">


                                                <CardCvcElement className="form-control"
                                                    options={{
                                                        style: {
                                                            base: {
                                                                fontSize: cardFontSize,
                                                                fontFamily: "Manrope, sans-serif",
                                                                color: "#808080",
                                                                fontWeight: "400",
                                                                padding: '10px'
                                                            },
                                                            invalid: {
                                                                color: "red",
                                                            },
                                                        },
                                                        placeholder: "CVV"
                                                    }}

                                                />

                                            </Col>
                                        </Row>
                                    </form>

                                </Container>

                            </Typography>


                        </AccordionDetails>
                    </Accordion>}
                    {/* review previous  */}
                    {/* {step == 1 && <Accordion expanded={expanded === 1} onChange={handleChange(1)} style={{ border: '0.3px solid #e9e9e9' }}>
                        <AccordionSummary style={{ borderBottom: '0.3px solid #e9e9e9', height: '60px' }}
                            expandIcon={
                                expanded ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography style={{ fontWeight: '500' }}>
                                <span style={{ fontSize: '12px' }}>Address</span>
                                <br /> <span>{address}</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Typography>
                                <Container fluid>
                                    <form onSubmit={orderNow}>
                                        <Row>
                                            <Col md={12} className="mt-3">
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    label="title"
                                                    value={title}
                                                    onChange={handleTitle}
                                                >
                                                    {titles && titles?.length > 0 &&
                                                        titles.map((title) => (
                                                            <option value={title.title} key={title.title}>
                                                                {title.title}
                                                            </option>
                                                        ))}
                                                </Form.Select>

                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <input type="text" className="form-control" placeholder="First Name" value={firstName} onChange={handleFirstName} />

                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <input type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={handleLastName} />
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <PhoneInput
                                                    defaultCountry="US"
                                                    placeholder="Phone No"
                                                    value={phone}
                                                    onChange={handlePhone}
                                                    className="phone-input"
                                                    maxLength={"15"}
                                                />
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    label="Country"
                                                    value={countryUrl}
                                                    onChange={handleCountry}
                                                >
                                                    <option>Country</option>
                                                    {country &&
                                                        country.map((data) => (
                                                            <option value={data?.url} key={data?.iso_3166_1_a3}>
                                                                {data?.printable_name}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <input type="text" className="form-control" placeholder="State" value={state} onChange={handleState} />

                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <input type="text" className="form-control" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <input type="number" className="form-control" placeholder="Postal Code" value={postCode} onChange={handlePostCode} />
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <input type="text" className="form-control" placeholder="Address" onChange={handleAddress} value={address} />
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <Ptext size='13' lineHeight='18' fontWeight='500' marginTop='0' color='#757575'>
                                                    In the US, our standard delivery is FedEx 3-5 working days, shipping out of Los Angeles, California. West coast customers typically wait 1 or 2 days, while delivery to the East coast typically takes the full 4 or 5 days.
                                                </Ptext>
                                                <Ptext size='13' lineHeight='18' fontWeight='500' marginTop='16' color='#757575'>
                                                    In the UK, we offer overnight delivery with FedEx. Once we dispatch your order, we will send you tracking details
                                                </Ptext>
                                            </Col>
                                        </Row>
                                    </form>


                                </Container>
                            </Typography>


                        </AccordionDetails>
                    </Accordion>} */}
                    {/* review previous  */}

                    {(step == 1 && Object.keys(selectedAddress).length !== 0) && <Row style={{ marginBottom: "20px" }}>
                        {/* {userAddressData?.length > 0 &&
                            [...userAddressData].reverse().map((data, index) => ( */}
                                <Col lg={"12"}>
                                    <Box
                                        sx={{
                                            border: '2px solid #000000',
                                            borderRadius: '10px',
                                            padding: '20px',
                                            position: 'relative',
                                            marginTop: '18px',
                                        }}
                                    >

                                        {/* Address Details */}
                                        <Ptext size={'14'} lineHeight="16" marginTop={'0'} style={{maxWidth:"90%"}}>
                                            {selectedAddress?.first_name + ' ' + selectedAddress?.last_name}
                                        </Ptext>
                                        <Ptext size={'14'} lineHeight="16" style={{maxWidth:"90%"}}>
                                            {selectedAddress?.line1 + ' ' + selectedAddress?.line2 + ' ' + selectedAddress?.line3 + ' ' + selectedAddress?.line4 + ' '}
                                        </Ptext>
                                        <Ptext size={'14'} lineHeight="16" style={{maxWidth:"90%"}}>
                                            {selectedAddress?.state + ' ' + selectedAddress?.postcode + ' ' + selectedAddress?.country_name}
                                        </Ptext>
                                        <Ptext size={'14'} lineHeight="16" style={{maxWidth:"90%"}}>
                                            {selectedAddress?.phone_number}
                                        </Ptext>

                                        {/* Edit and Delete icons */}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '10px',
                                                marginTop: '18px',
                                                display: 'flex'
                                            }}
                                        >
                                            <EditIcon
                                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                onClick={() => { navigate('/manage-address'); dispatch(isDrawer(IS_DRAWER, false)) }}
                                            />

                                            {/* <DeleteForeverIcon 
                                        style={{ marginLeft: '10px', cursor: 'pointer' }} 
                                        onClick={()=>deleteAddress(data)}
                                        /> */}
                                        </Box>
                                    </Box>
                                </Col>
                            {/* ))} */}
                    </Row>}

                    {(step == 1 && Object.keys(selectedAddress).length === 0) && 
                    <>
                    <BasicButton
                            text={<>Add address</>}
                            fontWeight={'500'}
                            padding={'11px 20px'}
                            background={'#1d1d1d'}
                            color={'#ffffff'}
                            border={'1px solid #1d1d1d'}
                            style={{ marginTop: '16px' }}
                            // disabled={submitLoader}
                            onClick={() =>{navigate('/manage-address'); dispatch(isDrawer(IS_DRAWER, false))}}
                            height={'48px'}
                        />
                    </>
                    }


                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center" style={{ marginTop: '84px' }}>
                <Ptext size='16' lineHeight='24' fontWeight='600' marginTop="0">Total</Ptext>
                <Ptext size='16' lineHeight='24' fontWeight='700' marginTop="0">{basket?.[0]?.price_currency=='gbp'?'$':'$'}{Number(localStorage.getItem("totalAmount"))}</Ptext>
            </div>
            {step == 2 &&
                <>
                    <BasicButton
                        text={<>Pay{submitLoader ? <CircularProgress style={{ 'color': 'white', 'width': '25px', 'height': '25px', marginLeft: '10px' }} /> : ''}</>}
                        fontWeight={'500'}
                        padding={'11px 20px'}
                        background={'#1d1d1d'}
                        color={'#ffffff'}
                        border={'1px solid #1d1d1d'}
                        style={{ marginTop: '16px' }}
                        disabled={submitLoader}
                        onClick={handleToken}
                        height={'48px'}
                    />

                    <div style={{ marginTop: '24px' }} >
                        <ApplePayComponent amount={Number(localStorage.getItem("totalAmount"))} handleCheckout={handleCheckout} />
                    </div>
                </>
            }
            {step == 1 && <BasicButton
                text={<>Pay with</>}
                fontWeight={'500'}
                padding={'11px 20px'}
                background={'#1d1d1d'}
                color={'#ffffff'}
                border={'1px solid #1d1d1d'}
                style={{ marginTop: '16px' }}
                disabled={submitLoader}
                onClick={() => handlePaymentNext(2)}
                height={'48px'}
            />}
            {step == 2 && <BasicButton
                text={<>Back</>}
                fontWeight={'500'}
                padding={'11px 20px'}
                background={'#1d1d1d'}
                color={'#ffffff'}
                border={'1px solid #1d1d1d'}
                style={{ marginTop: '16px' }}
                disabled={submitLoader}
                onClick={() => setStep(1)}
                height={'48px'}
            />}
        </div>
    )
}
export default Checkout;