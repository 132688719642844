import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FloatingLabel, Button, Card } from "react-bootstrap";
import 'pages/Store/Store.scss';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import url from 'services/urls.json';
import { getMethod } from "services/apiServices";
import Pagination from "react-js-pagination";
import { htmlToText } from 'html-to-text';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import H1text from "components/H1text/H1text";
import styled from "styled-components";
import { Box, Skeleton } from "@mui/material";
import Lottie from 'react-lottie';
import emptyList from 'assets/lottie/emptyList.json'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholderImage from 'assets/images/placeholderImage.png';
import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { containerVariants } from "services/common";
import Ptext from "components/Ptext/Ptext";
import riderProfileCallApi from "store/withoutToken/riderProfile/action";
import { RIDER_PROFILE } from "store/withoutToken/riderProfile/constant";





const ViewAll = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const riderProfile = useSelector((state) => state?.riderProfiletReducer?.payload?.data?.OTP);

    useEffect(() => {
        window.scrollTo(0, 0);
        getRiderProfile();
    }, [])

    const [loader, setLoader] = useState(false);
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: emptyList,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const getRiderProfile = () => {
        dispatch(riderProfileCallApi(RIDER_PROFILE, { key: "all" }, {
            run: true,
            handleFunction: (res) => { }
        }));
    };
    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            sx={{
                paddingBottom: {
                    xs: '12px',
                    sm: '24px',
                    md: '48px',
                    lg: '96px'
                },
            }}
            style={{ fontFamily: "Manrope, sans-serif", background: "#fafafa", minHeight: '90vh' }} className="store">
            <Container className="ps-3 pe-3">
                <Box
                    sx={{
                        paddingTop: {
                            xs: '16px',
                            sm: '16px',
                            md: '29px',
                            lg: '58px'
                        },
                    }}
                >
                    <Row >
                        <Col md={4}>
                            <H1text size='36' lineHeight={'36'} fontWeight='600' className="text-black " marginTop={'0'}>Rider Profile</H1text>
                        </Col>
                    </Row>
                </Box>
                {true ?
                    <>
                        {true ?
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '10px',
                                        sm: '10px',
                                        md: '21px',
                                        lg: '42px'
                                    },
                                    paddingBottom: {
                                        xs: '12px',
                                        sm: '24px',
                                        md: '48px',
                                        lg: '96px'
                                    },
                                }}
                            >
                                <Row >
                                    {riderProfile?.length > 0 && riderProfile?.map((item, index) => (
                                        <Col xs={12} sm={6} md={4} lg={3} xl={3} xxl={2} key={index} className="storeProducts g-3">
                                            <Box margin='0 8px' bgcolor={"#ffffff"} borderRadius='24px' padding="48px 0px 32px 0px" display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                                <Box
                                                    sx={{
                                                        backgroundImage: `url(${item?.profile_image ? item?.profile_image : placeholderImage})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat',
                                                        borderRadius: '50%',
                                                        height: '128px',
                                                        width: '128px',
                                                    }}
                                                >
                                                    {/* <img src={item?.profile_image} alt="" /> */}
                                                </Box>
                                                <Ptext size="20" lineHeight={"28"} fontWeight={"600"} marginTop='24' style={{ maxWidth: '296px', textAlign:'center' }}>{item?.first_name}</Ptext>
                                            </Box>
                                        </Col>
                                    ))}
                                </Row>
                            </Box>
                            :
                            <Row className="" style={{ marginTop: '42px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <Col md={6} lg={4} >

                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        maxWidth={400}
                                    />
                                    <H1text size='24' lineHeight={'28'} fontWeight='600' className="text-black " marginTop={'0'}>No item found.</H1text>
                                </Col>
                            </Row>
                        }

                    </>
                    :
                    <></>
                }
            </Container>
        </motion.div>
    );
};

export default ViewAll;
