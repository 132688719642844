import { EDIT_ADDRESS,EDIT_ADDRESS_ERROR,EDIT_ADDRESS_SUCCESS } from "./constant";
import { updateMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { takeLatest,put,take } from "redux-saga/effects";
import { ClearTokenRedirectLogin } from "context/context";

function* editAddressApi(action){
    try{
        let urlWithParams = url.userAddresses + action?.external?.key + '/';
        let response = yield updateMethodWithToken(urlWithParams,action.data);
        if(response?.status === 200 || response?.status === 201){
            yield put({type:EDIT_ADDRESS_SUCCESS,payload: response})
            if(action.external && action.external.run===true){
                action.external.handleFunction(response);
            }
        }
        else {
            yield put({type:EDIT_ADDRESS_ERROR,payload:response})
            if(response?.status === 401 || response?.status === 403){
                ClearTokenRedirectLogin(action.external.navigate)
              }else{
                action.external.handleError(response);
              }

        }
    }
    catch (error){
        yield put({type:EDIT_ADDRESS_ERROR , payload:error})
    }

}

function* editAddressSaga(){
    yield takeLatest(EDIT_ADDRESS , editAddressApi )
}

export default editAddressSaga;