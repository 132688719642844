import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FloatingLabel, Button, Card } from "react-bootstrap";
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import 'pages/Store/Store.scss';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethodWithToken } from "services/apiServices";
import Pagination from "react-js-pagination";
import { htmlToText } from 'html-to-text';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import { toast } from "react-toastify"
import { ClearTokenRedirectLogin } from "context/context";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import styled from "styled-components";
import PlaceholderInput from "components/Inputs/PlaceholderInput/PlaceholderInput";
import productDetailsCallApi from "store/productDetails/action";
import { PRODUCT_DETAILS } from "store/productDetails/constant";
import { CATEGORY_DROPDOWN } from "store/categoryDropdown/constant";
import categoryDropdownCallApi from "store/categoryDropdown/action";
import dropdownMasterCallApi from "store/dropdownMaster/action";
import { DROPDOWN_MASTER } from "store/dropdownMaster/constant";
import { Box, Skeleton } from "@mui/material";
import Lottie from 'react-lottie';

import emptyList from 'assets/lottie/emptyList.json'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholderImage from 'assets/images/placeholderImage.png';
import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { containerVariants } from "services/common";
import getFavouriteCallApi from "store/getFavourite/action";
import { GET_FAVOURITE } from "store/getFavourite/constant";

const WrapperImage = styled.div`
background: url(${props => props.image});
height: 346px;
max-width:346px;
margin:auto;
marginTop: 20px;
background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`

const itemsPerPageOptions = [
    { value: 10, label: '10' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 5, label: '5' },
];


function HtmlToPlainText({ htmlContent }) {
    const plainText = htmlToText(htmlContent, {
        wordwrap: 130
    });

    return <div>{plainText}</div>;
}

const Favourites = () => {
    const navigate = useNavigate()
    const [storeList, setStoreList] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [count, setCount] = useState(1);
    const [sortPrice, setSortPrice] = useState([]);
    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState('')
    const [price, setPrice] = useState('')
    const cartSelector = useSelector(state => state.commonReducer?.cart?.cart)
    const [totalQuantity, setTotalQuantity] = useState(cartSelector ?? 0);

    const categoryDropdown = useSelector(state => state?.categoryDropdownReducer?.payload?.data)
    const dropdownMasterColor = useSelector(state => state?.dropdownMasterReducer?.payload?.data)
    const [loader, setLoader] = useState(false);

    var [activePage, setPage] = useState(sessionStorage.getItem("currentPage") !== null ? parseInt(sessionStorage.getItem("currentPage")) : 1);


    useEffect(() => {
        handleSearchList();
    }, [itemsPerPage, price]);

    const handleItemsPerPageChange = (e) => {
        setPage(Number(e.target.value));
        setItemsPerPage(e.target.value)
    };

    const handleSortPrice = async () => {
        await getMethod(url.sortPrice).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setSortPrice(response.data)
            }
        })
    }

    const handleCategory = async () => {
        let apiUrl = `${url.categoryDropdown}?parent_slug=accessories`
        await getMethod(apiUrl).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setCategory(response.data)
            }
        })
    }
    const handlecategoryDropdwon = (e) => {
        setCategoryValue(e.target.value)
    }
    useEffect(() => {
        handleSortPrice();
        handleCategory();
    }, [])
    const handleSearchList = async () => {
        setPage(1);
        let apiUrl = `${url.buy}?catagory=accessories&paginate=true&page_limit=${itemsPerPage}&sort${price}`;
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response?.status === 200 || response?.status === 201) {
            setStoreList(response.data.results);
            setCount(response.data ? response.data.count : "");
        }
        else if (response?.status === 401 || response?.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }
    const handlePageChange = async (pageNumber) => {
        let apiUrl = `${url.buy}?catagory=accessories&page=${pageNumber}&paginate=true&page_limit=${itemsPerPage}&sort=${price}`;
        setPage(pageNumber);
        sessionStorage.setItem("currentPage", pageNumber);
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        setStoreList(response.data && response.data.results);

    };
    const handlePrice = (e) => {
        setPrice(e.target.value)
    }
    const dispatch = useDispatch();
    const [addCartloader, setAddCartloader] = useState(false);
    const [selectedProduct, setselectedProduct] = useState({});

    const handleAddCart = async (product) => {
        setselectedProduct(product);

        setAddCartloader(true);
        let body = {
            "url": product?.url,
            "quantity": 1
        }

        await postMethodWithToken(url.addCart, body).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart")
                dispatch(userCallApi(USER, {}, {
                    run: true,
                    handleFunction: (res) => handleCartCount(res?.data?.basket_id)
                }))

                localStorage.setItem('cartAPI', basket)
                localStorage.setItem("clearAllCart", response.data.url);
                setAddCartloader(false);
            }
            else if (response?.status === 401) {
                navigate('/signin');
                setAddCartloader(false);
            }
            else if (response?.status === 406) {
                toast.error("Out Of Stock");
                setAddCartloader(false);
            } else {
                setAddCartloader(false);
            }
        })

    }

    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res)
        }))
    };

    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
    };

    const [formValue, setFormvalue] = useState({
        sortByPrice: '',
        type: '',
        colors: ''
    });

    // const [filterList, setFilterList] = useState([]);

    // useEffect(() => {
    //     getItemList(formValue);
    // }, [formValue]);


    // const getItemList = async (value) => {
    //     setLoader(true);
    //     let apiUrl = `${url.buy}?sort=${value.sortByPrice}&catagory=accessories&paginate=true&page_limit=20&child=${value.type}&colour=${value.colors}`;

    //     let response;
    //     try {
    //         response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl);
    //         setFilterList(response?.data || []);
    //         setLoader(false)
    //     } catch (error) {
    //         console.error("Error fetching items:", error);
    //     }
    // };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: emptyList,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const getFavouriteReducer = useSelector((state)=>state?.getFavouriteReducer?.payload?.data)
    useEffect(() => {
        getFavourite();
    }, [])

    const getFavourite = () => {
        dispatch(getFavouriteCallApi(GET_FAVOURITE, {
            run: true,
            navigate: navigate,
            handleFunction: (res) => { toast.success(res?.data?.message); }
        }))
    };

    const getProductDetails = (id) => {
        dispatch(productDetailsCallApi(PRODUCT_DETAILS, { id: id }, {
          run: true,
          handleFunction: () => {
            navigate(`/productdetails/${id}`, { state: { type: 'scooter' } });
            // 
            window.scrollTo(0, 0);
          },
          toast: true,
          handleToast: (error) => { toast.error(error?.data?.detail); }
        }));
      };
    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            sx={{
                paddingBottom: {
                    xs: '12px',
                    sm: '24px',
                    md: '48px',
                    lg: '96px'
                },
            }}
            style={{ fontFamily: "Manrope, sans-serif", background: "#fafafa", minHeight: '90vh' }} className="store">
            <Container className="ps-3 pe-3">
                <Box
                    sx={{
                        paddingTop: {
                            xs: '16px',
                            sm: '16px',
                            md: '29px',
                            lg: '58px'
                        },
                    }}
                >
                    <Row >
                        <Col md={4}>
                            <H1text size='36' lineHeight={'36'} fontWeight='600' className="text-black " marginTop={'0'}>Favourites</H1text>
                        </Col>
                    </Row>
                </Box>
                {!loader ?
                    <>
                        {getFavouriteReducer?.length > 0 ?
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '10px',
                                        sm: '10px',
                                        md: '21px',
                                        lg: '42px'
                                    },
                                }}
                            >
                                <Row className="">
                                    {getFavouriteReducer?.length>0 && getFavouriteReducer?.map((item, index) => (
                                        <Col sm={12} md={6} lg={6} xl={3} xxl={3} key={index} className="storeProducts g-3">
                                            <Card className="cardProduct" style={{ background: '#ffffff', padding: '8px', border: 'none', borderRadius: '24px' }}>
                                                <div onClick={()=>getProductDetails(item?.id)} className="" style={{cursor:"pointer", borderRadius: '12px', background: '#fafafa', height: '250px', padding: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                                                    <LazyLoadImage
                                                        alt="Description of image"
                                                        src={item?.images[0]?.original}
                                                        placeholderSrc={placeholderImage}
                                                        style={{ borderRadius: '12px', background: '#fafafa' }}
                                                        effect="blur"
                                                    />
                                                </div>
                                                <Card.Body className="p-0" style={{ marginTop: '8px' }}>
                                                    <div style={{ background: '#fafafa', border: 'none', borderRadius: '12px', marginTop: '8px', padding: '12px 16px' }}>
                                                        <Card.Title style={{ fontSize: '20px', fontWeight: '700' }} className="title">{item?.title?.trim().split(/\s+/).slice(0, 2).join(' ')}</Card.Title>
                                                        <Card.Text  >
                                                            {/* <div className="description">
                                                            <HtmlToPlainText htmlContent={item?.description} />
                                                        </div> */}
                                                            <div style={{ fontWeight: '700', fontSize: '20px' }}>
                                                                <p>${item?.price_incl_tax}
                                                                </p>
                                                            </div>
                                                        </Card.Text>
                                                        <Button variant="outline-dark" className="rounded-3 p-2 fw-bold CartButton w-100 d-flex justify-content-center"
                                                            onClick={() => handleAddCart(item)}>

                                                            {item?.id == selectedProduct?.id && addCartloader ?
                                                                <CircularProgress style={{ 'color': '#808080', 'width': '24px', 'height': '24px', marginLeft: '8px' }} />
                                                                : "Add to Cart"}
                                                        </Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Box>
                            :
                            <Row className="" style={{ marginTop: '42px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <Col md={6} lg={4} >

                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        maxWidth={400}
                                    />
                                    <H1text size='24' lineHeight={'28'} fontWeight='600' className="text-black " marginTop={'0'}>No item found.</H1text>
                                </Col>
                            </Row>
                        }
                        <div className="" style={{ marginTop: (getFavouriteReducer?.previous || getFavouriteReducer?.next) ? '76px' : '0' }}>
                            {count > 1 ? (
                                <Pagination
                                    prevPageText={
                                        getFavouriteReducer?.previous ? <Button variant="outline-dark" className="w-35 fw-bold CartButton pe-4 ps-4 pt-2 pb-2" disabled={count < itemsPerPage}>
                                            <KeyboardDoubleArrowLeftIcon />
                                            <span className="ms-2">Previous</span>
                                        </Button> : ""
                                    }
                                    nextPageText={
                                        getFavouriteReducer?.next ? <Button variant="outline-dark" className="w-35 fw-bold CartButton pe-4 ps-4 pt-2 pb-2" disabled={count < itemsPerPage}>
                                            <span className="ms-2">Next</span>
                                            <KeyboardDoubleArrowRightIcon />
                                        </Button> : ""
                                    }
                                    activePage={activePage}
                                    itemsCountPerPage={itemsPerPage}
                                    totalItemsCount={count}
                                    onChange={handlePageChange}
                                    marginPagesDisplayed={0}
                                    hideFirstLastPages={true}
                                    pageRangeDisplayed={0}
                                    itemClass="page-item"

                                />
                            ) : null}
                        </div>
                    </>
                    :
                    <Box
                        sx={{
                            marginTop: {
                                xs: '10px',
                                sm: '10px',
                                md: '21px',
                                lg: '42px'
                            },
                        }}
                    >
                        <Row className="w-100" >
                            {[1, 2, 3, 4].map((_, index) => (
                                <Col sm={12} md={6} lg={6} xl={3} xxl={3} key={index} className="d-flex g-3">
                                    <div className="h-100 w-100 d-flex flex-column" style={{ background: '#ffffff', borderRadius: '24px', padding: '16px' }}>
                                        <Skeleton variant="rectangular" width="100%" height={'250px'} style={{ borderRadius: '24px' }} />
                                        <Box mt={2} display='flex' justifyContent='start' gap={3}>
                                            <Skeleton variant="text" width={'75%'} height={'20px'} />
                                        </Box>
                                        <Box mt={2} display='flex' justifyContent='start' gap={3}>
                                            <Skeleton variant="text" width={'100%'} height={'20px'} />
                                        </Box>
                                        <Box mt={2} className="d-flex justify-content-between">
                                            <Skeleton variant="rectangular" width="100%" height={36} />
                                        </Box>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Box>
                }
            </Container>
        </motion.div>
    );
};

export default Favourites;
