import React, { useState, useEffect } from "react";
import 'pages/orderList/orderList.scss';
import { Container, Row, Col, Button } from "react-bootstrap";
import scooter from "assets/images/orderListScooter.png";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_HISTORY } from "store/orderHistory/constant";
import orderHistoryCallApi from "store/orderHistory/action";
import moment from 'moment';
import { Box, Skeleton } from "@mui/material";
import Lottie from 'react-lottie';

import emptyList from 'assets/lottie/emptyList.json'
import H1text from "components/H1text/H1text";
import { containerVariants } from "services/common";
import { motion } from "framer-motion";
import placeholderImage from 'assets/images/placeholderImage.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import orderDetailsCallApi from "store/orderDetails/action";
import { ORDER_DETAILS } from "store/orderDetails/constant";
import Ptext from "components/Ptext/Ptext";
import { ACTIVE_SUBSCRIPTIONS } from "store/activeSubscriptions/constant";
import activeSubscriptionsCallApi from "store/activeSubscriptions/action";

const ActiveSubscription = () => {

    const array = [1, 2, 3, 4, 5];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activeSubscriptions = useSelector((state) => state?.activeSubscriptionsReducer?.payload?.data?.data);
    const activeSubscriptionsloader = useSelector((state) => state?.activeSubscriptionsReducer?.loading);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: emptyList,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        getActiveSubscriptions();
    }, [])

    const handleOrderDetail = (item) => {
        getOrderDetails(item?.billing_address?.id);
        // orderHistoryCallApi
        navigate('/orderdetail', { state: { item: item } });
    }
    const getActiveSubscriptions = () => {
        dispatch(activeSubscriptionsCallApi(ACTIVE_SUBSCRIPTIONS, {}, { run: true, navigate: navigate, handleFunction: (res) => { } }))
    };

    const orderDetails = useSelector((state) => state?.orderDetailsReducer?.payload?.data);
    const getOrderDetails = (id) => {
        dispatch(orderDetailsCallApi(ORDER_DETAILS, { key: id }, { run: true, navigate: navigate, handleFunction: () => { } }))
    };
    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="orderList" style={{ fontFamily: "Manrope, sans-serif" }}>
            <Container className="ps-3 pe-3">
                <Row>
                    {/* <p className="orderHeading">Active Subscription</p> */}
                    <H1text size='36' lineHeight={'39.6'} fontWeight='600'>Active Subscription</H1text>
                    {/* !orderListloader  */}
                    {!activeSubscriptionsloader ?
                        <>
                            <Col md={12} className="mb-5 mt-4">
                                {activeSubscriptions?.length > 0 ? activeSubscriptions.map((item, index) => (
                                    <div className="mb-3 items" >
                                        <div className="deliveryDate d-flex justify-content-between align-items-center px-4 py-2">
                                            {/* <p className=" p-2 fw-bold mb-0">Out for delivery
                                                <span className="date ps-2">Expected to arrive Today</span>
                                            </p> */}
                                            <p className="price mb-0"> <span className="priceValue"> {item?.subscribed_package?.name}</span></p>
                                            {/* <Button variant="outline-dark" className="fw-bold"  >Cancel</Button> */}
                                        </div>
                                        <Container className="p-3">
                                            <Row className="justify-content-between flex-lg-row flex-column ">
                                                <Col md={12} lg={10} xl={8} xxl={8} sm={12} xs={12}>
                                                    <Row className="">
                                                        <Col sm={6} md={2} className=" " style={{ paddingRight: '10px !important', paddingBottom: '10px' }}>
                                                            {/* <img src={item?.product_images[0]} alt="scooter" className="orderImage" /> */}

                                                            <LazyLoadImage
                                                                alt="scooter" className="orderImage"
                                                                src={placeholderImage}
                                                                // src={item?.product_images?.[0]}
                                                                placeholderSrc={placeholderImage}
                                                                height="96px"
                                                                width="96px"
                                                                effect="blur"
                                                            />
                                                            {/* <img src={item?.product_image?.[0]?.original} alt="scooter" className="orderImage" /> */}
                                                        </Col>
                                                        <Col sm={6} md={10} className="p-md-0 p-lg-0 p-xl-0">

                                                            <div className="d-md-flex gap-2">
                                                                <p className="price mb-0">Subscription Price: <span className="priceValue"> ${item?.subscribed_package?.price}</span></p>
                                                              
                                                                {/* <p className="price mb-0">Quantity:<span className="quantityValue">{item?.quantity}</span></p> */}
                                                            </div>
                                                            <div className=" gap-2 pt-sm-1 ">
                                                                <p className="price mb-0">Order Total:
                                                                    <span className="priceValue"> ${item?.amount_paid}</span>
                                                                </p>
                                                                <p className="price mb-0">Order placed on:
                                                                    <span className="priceValue"> {moment(item?.purchased_on).format('DD MMM YYYY, dddd')}</span>
                                                                </p>
                                                                <Box  
                                                                sx={{
                                                                    display:{
                                                                        xs:"block",
                                                                        md:'flex'
                                                                    }
                                                                }} 
                                                                justifyContent='space-between'>
                                                                <p className="price mb-0">Start billing cycle:
                                                                    <span className="priceValue"> {moment(item?.current_billing_cycle?.period_start).format('DD MMM YYYY, dddd')}</span>
                                                                </p>
                                                                <p className="price mb-0">End billing cycle:
                                                                    <span className="priceValue"> {moment(item?.current_billing_cycle?.period_end).format('DD MMM YYYY, dddd')}</span>
                                                                </p>
                                                                </Box>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                                
                                            </Row>
                                        </Container>

                                    </div>
                                )) :
                                    <Box
                                        sx={{
                                            margin: {
                                                xs: "14px 0",
                                                sm: "28px 0",
                                                md: "75px 0",
                                                lg: "150px 0"
                                            }

                                        }}
                                    >
                                        <Row className="" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            <Col md={6} lg={4} >

                                                <Lottie
                                                    options={defaultOptions}
                                                    height={400}
                                                    maxWidth={400}
                                                />
                                                <H1text size='24' lineHeight={'28'} fontWeight='600' className="text-black " marginTop={'0'}>No Subscription found.</H1text>
                                            </Col>
                                        </Row>
                                    </Box>
                                }

                            </Col>
                        </>
                        :
                        <>
                            {Array.from(new Array(6)).map((_, index) => (
                                <Box className='ps-3 pe-3'>
                                    <div className="mb-5 mt-4 items" key={index}>
                                        <Skeleton variant="text" width="100%" height={40} className="mb-2" />
                                        <Container className="p-3">
                                            <Row className="justify-content-between flex-lg-row flex-column ">
                                                <Col md={12} lg={10} xl={8} xxl={8} sm={12} xs={12}>
                                                    <Row>
                                                        <Col sm={6} md={2} style={{ paddingRight: '10px !important', paddingBottom: '10px' }}>
                                                            <Skeleton variant="rectangular" width={100} height={100} className="orderImage" />
                                                        </Col>
                                                        <Col sm={6} md={10}>
                                                            <Skeleton variant="text" width="60%" height={25} className="mb-2" />
                                                            <div className="d-md-flex gap-2">
                                                                <Skeleton variant="text" width="30%" height={20} />
                                                                <Skeleton variant="text" width="30%" height={20} />
                                                            </div>
                                                            <div className="d-md-flex gap-2 pt-sm-1">
                                                                <Skeleton variant="text" width="30%" height={20} />
                                                                <Skeleton variant="text" width="30%" height={20} />
                                                                <Skeleton variant="text" width="30%" height={20} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} lg={4} xxl={4} sm={12} xs={12}>
                                                    <div className="d-flex justify-content-around pt-3 w-100 gap-3 align-items-center justify-content-xl-end">
                                                        {/* <Skeleton variant="circular" width={24} height={24} />
                                                        <Skeleton variant="text" width="20%" height={20} />
                                                        <Skeleton variant="rectangular" width={100} height={36} /> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Box>
                            ))}

                        </>}

                </Row>
            </Container>

        </motion.div>

    )
}

export default ActiveSubscription;