import { USER_ADDRESS,USER_ADDRESS_ERROR,USER_ADDRESS_SUCCESS } from "./constant";
import { getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { takeLatest,put,take } from "redux-saga/effects";
import { ClearTokenRedirectLogin } from "context/context";

function* getUserAddressApi(action){
    try{
        let response = yield getMethodWithToken(url.userAddresses);
        if(response?.status === 200 || response?.status === 201){
            yield put({type:USER_ADDRESS_SUCCESS,payload: response})
            if(action.external && action.external.run===true){
                action.external.handleFunction(response);
            }
        }
        else {
            yield put({type:USER_ADDRESS_ERROR,payload:response})
            if(response?.status === 401 || response?.status === 403){
                ClearTokenRedirectLogin(action.external.navigate)
              }else{
                action.external.handleError(response);
              }
        }
    }
    catch (error){
        yield put({type:USER_ADDRESS_ERROR , payload:error})
    }

}

function* userAddressSaga(){
    yield takeLatest(USER_ADDRESS ,getUserAddressApi )
}

export default userAddressSaga;