import { React, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../SignIn/SignIn.scss';
import Button from 'react-bootstrap/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate, useLocation} from 'react-router-dom';
import { postMethod } from 'services/apiServices';
import url from 'services/urls.json';
import { setToken } from 'services/common';
import { toast } from "react-toastify";
import Ptext from 'components/Ptext/Ptext';
import H1text from 'components/H1text/H1text';
import { Box } from '@mui/material';
import styled from "styled-components";

const Input = styled.input`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height:48px;
  border-radius:12px;

  ::placeholder {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
`;
const SetPassoword = ()=>{
    const navigate=useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [userId , setUserId]=useState('')
    const [showPassword,setShowPassword]=useState(false)
    const [showConfirmPassword,setShowConfirmPassword]=useState(false)
    const [email , setEmail]=useState('')
    const [password , setPassword]=useState('')
    const [confirmPassword , setConfirmPassword]=useState('')
    const [error, setError] = useState(''); 
    const [pwdError, setPwdError] = useState('');

    const validatePassword = (pwd) => {
        if (pwd?.length < 8 ||
            !/[A-Z]/.test(pwd) ||           // Check for at least one uppercase letter
            !/\d/.test(pwd) ||              // Check for at least one digit
            !/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) {
          return 'Password must be at least 8 characters long with at least one digit , one special character and one Capital character';
        }
        return '';
      };
      const validateConfirmPassword = (pwd)=>{
        if(password!== pwd){
            return 'confirm password not same as password';
        }
      }

     const handlePasswordChange = (e) => {
        
        setPassword(e.target.value)
        setError(validatePassword(e.target.value));
     };
     const handleConfirmPasswordChange = (e) => {
       
        setConfirmPassword(e.target.value)
        setPwdError(validateConfirmPassword(e.target.value))
     };
    const getFormData =(e)=>{
        e.preventDefault()
        if(password==confirmPassword){
                setPasswordAPI()
        }else{
            setPwdError('confirm password not same as password');
        }
    }

    const setPasswordAPI = async()=>{
        let body ={
            userId:userId,
            password:password
        }
        await postMethod(url.setPassword , body).then((response)=>{
            if(response?.status === 200 || response?.status === 201){
                navigate("/signin")
                toast.success(response.data.response)
            }
            else {
                toast.error(response.data.error)
            }
        })
    }
    useEffect(()=>{
        verifyToken()
    },[])

    const verifyToken= async() =>{
        let body ={
            "token":token
        }
        await postMethod(url.verifyToken,body).then((response)=>{
                if(response?.status === 200 || response?.status === 201){
                    setUserId(response.data.userId)
                }
                else if(response.status === 404){
                    toast.error('It looks like your reset link is either missing or has expired. Please request a new link to reset your password.')
                }else{
                    toast.error(response.data.error)
                }
        })
    }

    return (
        <Box style={{width:'100%',height:'62vh',minHeight:'fit-content', display:'flex', alignItems:'center', justifyContent:'center' }}>
        <div style={{fontFamily:"Manrope, sans-serif"}}>
            <Container className='' style={{ maxWidth: '392px', padding:'0', padding:'16px' }}>

                    <Box style={{ textAlign: 'center' }}>
                    <H1text size='36' lineHeight='34' fontWeight='600' marginBottom="24" marginTop={'190'}>Restore Password</H1text>
                    <div >
                        <Ptext size='16' lineHeight='24' fontWeight='500' marginTop="24">Create a new password for your account.</Ptext>
                    </div>
                </Box>
                        <div className='form_box' style={{marginTop:'32px'}} >
                            <form onSubmit={getFormData}>
                            
                                <div className=' relative'>
                                    <Input type={showPassword ? 'text' : 'password'} placeholder='New password' name='password' autoFocus value={password} className="form-control" onChange={handlePasswordChange} required />
                                    <span  className="passwordIcon cursor-pointer" style={{position:'absolute',top:'10px',right:'10px'}} onClick={()=>setShowPassword(!showPassword)} >{showPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}</span>
                                    {error && <p  className='text-danger pt-2 text-left' style={{fontSize:'12px'}}>{error}</p>}
                                </div>
                                <div className=' relative' style={{marginTop:'20px'}}>
                                    <Input type={showConfirmPassword ? 'text' : 'password'} placeholder='Confirm Password' name='Confirm new password'  value={confirmPassword} className="form-control" onChange={handleConfirmPasswordChange} required />
                                    <span  className="passwordIcon cursor-pointer" style={{position:'absolute',top:'10px',right:'10px'}} onClick={()=>setShowConfirmPassword(!showConfirmPassword)} >{showConfirmPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}</span>
                                    {pwdError && <p className='text-danger pt-2 text-left' style={{fontSize:'12px'}}>{pwdError}</p>}
                                </div>
                                <button type='submit' className='btn btn-dark btn-lg submit w-100' style={{marginBottom:'190px', height:'48px', marginTop:'24px', borderRadius:'12px'}}> <Ptext size='16' lineHeight='24' fontWeight='600' marginTop="0">Reset and Log In</Ptext></button>
                            </form>
                        </div>
            </Container>  
        </div>
        </Box>
    )
}
export default SetPassoword