import { combineReducers } from "redux";
import signUpReducer from "./SignUp/reducer";
import countriesReducer from './Countries/reducer'
import commonReducer from './common/reducer'
import checkoutReducer from "./checkout/reducer";
import userReducer from "./user/reducer";
import cartReducer from "./cart/reducer";
import signInReducer from "./signIn/reducer";
import paymentIntentReducer from "./paymentIntent/reducer";
import logOutReducer from "./logOut/reducer";
import orderHistoryReducer from "./orderHistory/reducer";
import productDetailsReducer from "./productDetails/reducer";
import categoryDropdownReducer from "./categoryDropdown/reducer";
import dropdownMasterReducer from "./dropdownMaster/reducer";
import addFavouriteReducer from "./addFavourite/reducer";
import getFavouriteReducer from "./getFavourite/reducer";
import anonymousReducer from "./anonymous/reducer";
import orderDetailsReducer from "./orderDetails/reducer";
import activeSubscriptionsReducer from "./activeSubscriptions/reducer";
import bannersListReducer from "./withoutToken/bannersList/reducer";
import riderProfiletReducer from "./withoutToken/riderProfile/reducer";
import companyReviewsReducer from "./withoutToken/companyReviews/reducer";
import plansProductReducer from "./withoutToken/plansProduct/reducer";
import userAddressReducer from "./userAddress/reducer";
import addAddressReducer from "./addAddress/reducer";
import editAddressReducer from "./editAddress/reducer";
import deleteAddressReducer from "./deleteAddress/reducer";

export default combineReducers({
    signUpReducer,
    countriesReducer,
    commonReducer,
    checkoutReducer,
    userReducer,
    cartReducer,
    signInReducer,
    paymentIntentReducer,
    logOutReducer,
    orderHistoryReducer,
    productDetailsReducer,
    categoryDropdownReducer,
    dropdownMasterReducer,
    addFavouriteReducer,
    getFavouriteReducer,
    bannersListReducer,
    riderProfiletReducer,
    companyReviewsReducer,
    anonymousReducer,
    orderDetailsReducer,
    plansProductReducer,
    activeSubscriptionsReducer,
    userAddressReducer,
    addAddressReducer,
    editAddressReducer,
    deleteAddressReducer,

})