import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { isDrawer } from 'store/common/action';
import { IS_DRAWER } from 'store/common/constant';
import styled from 'styled-components';


export default function CustomDrawer(props) {
  const dispatch = useDispatch();
  const drawerValue = useSelector((state) => state?.commonReducer?.isDrawer);

  const [test, setTest] = React.useState(false)
 
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    
    // Close drawer only if the event target is not an input
    if (!(event.target instanceof HTMLInputElement)) {
      dispatch(isDrawer(IS_DRAWER, open));
      setTest(open);
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: {
        xs:anchor === 'top' || anchor === 'bottom' ? 'auto' : 260,
        sm:anchor === 'top' || anchor === 'bottom' ? 'auto' : 360
      } 
    }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {props.content}
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button className='p-0' onClick={toggleDrawer(true)}>
            {props.icon}
          </Button>
          <Drawer
            anchor={anchor}
            open={drawerValue==false ? drawerValue :test} // Use the Redux state for controlling the drawer
            onClose={toggleDrawer(false)}
            transitionDuration={{ enter: 600, exit: 300 }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
