import { DELETE_ADDRESS,DELETE_ADDRESS_ERROR,DELETE_ADDRESS_SUCCESS } from "./constant";
import { deleteMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { takeLatest,put,take } from "redux-saga/effects";
import { ClearTokenRedirectLogin } from "context/context";

function* deleteAddressApi(action){
    try{
        let urlWithParams = url.userAddresses + action?.external?.key + '/';
        let response = yield deleteMethodWithToken(urlWithParams);
        if(response?.status === 200 || response?.status === 201 || response?.status === 204 ){
            yield put({type:DELETE_ADDRESS_SUCCESS,payload: response})
            if(action.external && action.external.run===true){
                action.external.handleFunction(response);
            }
        }
        else {
            yield put({type:DELETE_ADDRESS_ERROR,payload:response})
            if(response?.status === 401 || response?.status === 403){
                ClearTokenRedirectLogin(action.external.navigate)
              }else{
                action.external.handleError(response);
              }

        }
    }
    catch (error){
        yield put({type:DELETE_ADDRESS_ERROR , payload:error})
    }

}

function* deleteAddressSaga(){
    yield takeLatest(DELETE_ADDRESS , deleteAddressApi )
}

export default deleteAddressSaga;