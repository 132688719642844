import { React, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../SignIn/SignIn.scss';
import Button from 'react-bootstrap/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import { postMethod } from 'services/apiServices';
import url from 'services/urls.json';
import { setToken } from 'services/common';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { signupCallApi } from 'store/SignUp/action';
import { SIGN_IN } from 'store/signIn/constant';
import signCallApi from 'store/signIn/action';
import Ptext from 'components/Ptext/Ptext';
import { Box } from '@mui/material';
import H1text from 'components/H1text/H1text';
import {CircularProgress } from "@mui/material";

const SignIn = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validEmail, setValidEmail] = useState(true)

    const signInDataLoader = useSelector(state => state?.signInReducer?.loading)

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    };
    const getFormData = (e) => {
        let body = {
            username: email,
            password: password
        }
        e.preventDefault()
        dispatch(signCallApi(SIGN_IN, body, {
            run: true,
            handleFunction: (res) => handleSuccess(res),
            toast: true,
            handleToastError: (res) => handleFailure(res)
        }))
    }

    const handleSuccess = (res) => {
        localStorage.setItem("token", res.data.token)
        toast.success("Signin successfully !")
        navigate('/')
    }
    const handleFailure = (res) => {
        toast.error("Invalid Login")
        localStorage.removeItem('basketId')
        navigate('/signin')
    }

    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email && !emailRegex.test(email)) {
            setValidEmail(false);
        } else {
            setValidEmail(true);
        }
    }, [email]);

    // Prevent back navigation
    useEffect(() => {
        const preventBack = () => {
            window.history.pushState(null, document.title, window.location.href);
        };

        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', preventBack);

        return () => {
            window.removeEventListener('popstate', preventBack);
        };
    }, []);

    return (
        <Box style={{ width: '100%', height: '62vh', minHeight: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box className='SignIn' style={{ width: '392px', padding: '16px' }}>
                <div style={{marginTop:'122px'}}>
                    <H1text size='36' lineHeight='36' fontWeight='600' style={{ marginBottom: '32px', textAlign: 'center' }}>Sign In</H1text>
                </div>
                <div className='form_box' style={{ marginTop: '32px' }} >
                    <form onSubmit={getFormData}>
                        <div style={{ marginBottom: '20px' }}>
                            <input type='email' placeholder='Email' name='email' autoFocus value={email} className="form-control" onChange={handleEmailChange} required />
                            {!validEmail && <p className='text-danger pt-2 text-start' style={{ fontSize: '12px' }}>Please enter a valid email address.</p>}
                        </div>
                        <div className='relative' style={{ marginBottom: '20px' }}>
                            <input type={showPassword ? 'text' : 'password'} placeholder='Password' name='password'  value={password} className="form-control" onChange={handlePasswordChange} required />
                            <span className="passwordIcon cursor-pointer" onClick={() => setShowPassword(!showPassword)} >{showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</span>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <Ptext size='14' lineHeight='20' fontWeight='500' onClick={() => navigate("/forgotpassword")} className='text-left text-decoration-underline text-primary mb-3 cursor-pointer'>Forgot password?</Ptext>
                        </div>
                        <button type='submit' className='btn btn-dark btn-lg submit w-100 d-flex justify-content-center align-items-center' style={{position:'relative', marginBottom: '136px' }} > 
                        <Ptext size='16' lineHeight='24' fontWeight='400' marginTop='0'>Sign In</Ptext> 
                        {signInDataLoader&&<CircularProgress style={{position:'absolute',right:'20px', width: '20px', height: '20px', cursor: 'pointer', color:'#ffffff', marginLeft:'20px' }} />}
                        </button>
                        <div className='text-center'>
                            <Ptext size='14' lineHeight='20' fontWeight='500' marginTop='0' className='mb-0 fw-500' style={{ fontSize: '14px', marginBottom: '4px' }}> Still don’t have an account?  </Ptext>
                            <Ptext size='14' lineHeight='20' fontWeight='500' onClick={() => navigate("/signup")} marginTop='0' className='text-center fw-600 cursor-pointer' style={{ color: '#0d6efd', marginBottom:'122px' }} >Sign up</Ptext>
                        </div>
                    </form>
                </div>
            </Box>
        </Box>
    )
}

export default SignIn;
