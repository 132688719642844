
import { ADD_ADDRESS,ADD_ADDRESS_ERROR,ADD_ADDRESS_SUCCESS } from "./constant";

const addAddressReducer=(state={},action)=>{

    switch(action.type){
        case ADD_ADDRESS:
            return {
                ...state,
                loading:true,
                error:''
            }
            case ADD_ADDRESS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload,
                type:ADD_ADDRESS_ERROR
            }
            case ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                loading:false,
                error:'',
                payload:action.payload,
                type:ADD_ADDRESS_SUCCESS
            }
            default:
                return state;
    }
}
export default addAddressReducer;