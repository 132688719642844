import { ADD_ADDRESS } from "./constant";

const addAddressCallApi=(type , data , external = {})=>{

    switch(type){
        case ADD_ADDRESS:
            return {
                type:type,
                data:data,
                external:external
            }
        default:
            return {
                type:type
            }    
    }

}
export default addAddressCallApi;