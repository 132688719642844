
import { USER_ADDRESS,USER_ADDRESS_ERROR,USER_ADDRESS_SUCCESS } from "./constant";

const userAddressReducer=(state={},action)=>{

    switch(action.type){
        case USER_ADDRESS:
            return {
                ...state,
                loading:true,
                error:''
            }
            case USER_ADDRESS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload,
                type:USER_ADDRESS_ERROR
            }
            case USER_ADDRESS_SUCCESS:
            return {
                ...state,
                loading:false,
                error:'',
                payload:action.payload,
                type:USER_ADDRESS_SUCCESS
            }
            default:
                return state;
    }
}
export default userAddressReducer;