import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

function CommonDialog({
  width = 862,
  padding = '32px',
  handleClose = () => {},
  modalActive,
  innerContent,
}) {
  return (
    <Dialog
      open={modalActive}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      scroll="body"
      maxWidth={false}
      PaperProps={{
        sx: {
          width: width ? width : '100%',
          maxWidth: 1260,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '16px',
          p: padding,
          '@media (max-width: 767px)': {
            padding: '10px',
            margin: '10px',
          },
        },
      }}
    >
      <Box display='flex' justifyContent='end' marginBottom='10px'>
        <IconButton onClick={handleClose}>
          <CloseIcon style={{padding:'0'}} />
        </IconButton>
      </Box>
      <DialogContent style={{padding:'10px', overflow:'hidden'}}>{innerContent}</DialogContent>
    </Dialog>
  );
}

export default CommonDialog;
