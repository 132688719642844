import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Menu, MenuItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import '../Navbar/Navbar.scss';
import { Stack } from 'react-bootstrap';
import { getToken } from 'services/common';
import { postMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import userCallApi from 'store/user/action';
import { USER } from 'store/user/constant';
import cartCallApi from 'store/cart/action';
import { CART } from 'store/cart/constant';
import { commonCallApi, productID } from 'store/common/action';
import { COMMON_DETAILS, PRODUCT_ID } from 'store/common/constant';
import { ClearTokenRedirectLogin, ClearTokenRedirectLogout } from 'context/context';
import storage from "redux-persist/lib/storage";
import { persistor } from "store";
import logoutCallApi from 'store/logOut/action';
import { LOGOUT } from 'store/logOut/constant';
import { Image } from '@mui/icons-material';
import CustomDrawer from 'components/CustomDrawer/CustomDrawer';
import Cart from 'pages/Cart/Cart';

import blackCart from 'assets/svg/blackCart.svg'
import profile from 'assets/svg/profile.svg'
import styled from 'styled-components';

const AnimatedMenuIcon = styled(MenuIcon)(({ theme, open }) => ({
  transition: 'transform 2s ease',
  transform: open ? 'rotate(360deg)' : 'rotate(0deg)',
}));



const drawerWidth = 240;
const navItems = [
  { name: 'Scooters', icon: "", path: "scooters" },
  { name: 'Bikes', icon: "", path: "bikes" },
  { name: 'Store', icon: "", path: "store" },
  { name: 'Compare', icon: "", path: "compare" },
  { name: 'Metro+', icon: "", path: "metro" },
  { name: 'Our Vision', icon: "", path: "ourvision" },

];
// { name: 'Cart', icon: "https://metrorides.theclientdemos.com/assets/images/cart.svg", path: "Cart" }

const authItems = [{ name: 'Sign In', path: "SignIn" }, { name: 'Sign Up', path: "signUp" }];
const userMe = [{ name: 'My Account', path: '' }];

export default function DrawerAppBar(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  // const location=useLocation();
  const { windowCustom } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [token, setToken] = useState('');
  const [userDetail, setUserDetail] = useState([])
  const selectedProduct = useSelector(state => state?.commonReducer?.cart?.cart);
  const userProfile = useSelector(state => state?.userReducer?.payload?.data);
  React.useEffect(() => {
    if (!isMobile) {
      setMobileOpen(false);
    }
  }, [isMobile]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const location = useLocation()
  const handleLogOut = async () => {
    await postMethodWithToken(url.logOut).then((response) => {
      if (response?.status === 200 || response?.status === 201) {
        setToken('');
        handleClose();
        ClearTokenRedirectLogout(navigate, 'logout')

      }
      else {
        handleClose()
        setToken('');
        localStorage.removeItem("token")
        navigate("/signin")
      }
    })

  }
  // const handleLogOut=()=>{
  //   dispatch(logoutCallApi(LOGOUT,{},{run:true,
  //     handleFunction:(res)=>handleSuccessLogOut(res)
  //   }))
  // }
  // const handleSuccessLogOut=(res)=>{
  //   setToken('');
  //   handleClose();
  //   // navigate("/signin")
  // }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const [tabItem, setTabItem] = useState("")
  const handlePage = (item) => {
    if (item != 'empty') {
      dispatch(productID(PRODUCT_ID, ""))
      navigate(`/${item?.path}`);
      setTabItem(item.name);
    } else {
      setTabItem('');

    }
  };
  const handleHomePage = () => {

    navigate('/');
    handlePage("empty")
  }
  const handleUser = () => {

    dispatch(userCallApi(USER, {}, {
      run: true,
      handleFunction: (res) => handleUserDetail(res)
    }))

  }
  const [basketId, setBasketId] = useState(null)
  const handleUserDetail = (res) => {
    setUserDetail(res?.data)
    // localStorage.setItem('basektId', res?.data?.basket_id);
    handleCart(res?.data?.basket_id);
    setBasketId(res?.data?.basket_id)
  }
  const handleCart = (basketId) => {
    dispatch(cartCallApi(CART, {}, {
      url: basketId === null ? `ecom/baskets/` : `ecom/baskets/${basketId}/lines`,
      run: true,
      handleFunction: (res) => handleSuccess(res),

    }))
  };
  const handleOrderList = () => {
    handleClose()
    navigate('/orderlist')
    setTabItem('');
  }
  const handleActiveSubscription = () => {
    handleClose()
    navigate('/active-subscription')
    setTabItem('');
  }
  const handleAddress = () => {
    handleClose()
    navigate('/manage-address')
    setTabItem('');
  }
  const handleFavourites = () => {
    handleClose()
    navigate('/favourites')
    setTabItem('');
  }

  const handleSuccess = (res) => {
    dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      handleUser();
    }
  }, [localStorage.getItem('token')]);

  const location2 = useLocation();



  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className='navbar' >
      <Typography variant="h6" sx={{ my: 2 }}>
        <img style={{ cursor: "pointer" }} src="https://metrorides.theclientdemos.com/assets/images/logo.svg" alt="Logo" onClick={handleHomePage} />
      </Typography>
      <Divider />

      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding >
            <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} onClick={() => handlePage(item)}>

              {selectedProduct > 0 && item?.name == 'Cart' &&
                <div className='absolute carticonmobile'>{selectedProduct}</div>}

              <img src={item.icon} className='me-0 ' />
              <ListItemText primary={item.name} style={{ paddingTop: '0 !important' }} />
            </ListItemButton>
          </ListItem>
        ))}
        {localStorage.getItem("token") && !userProfile?.is_anonymous ? (

          userMe.map((item, index) => (
            <div key={index}>
              {/* <ListItem key={index} disablePadding>
                <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} >
                   <ListItemText onClick={()=>navigate('/')}>Dashboard</ListItemText>
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding >
                <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} >
                  <ListItemText onClick={handleOrderList}>OrderList</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} >
                  <ListItemText onClick={handleActiveSubscription}>Active subscription</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} >
                  <ListItemText onClick={handleAddress}>Manage Address</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} >
                  <ListItemText onClick={handleFavourites}>Favourites</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} >
                  <ListItemText onClick={handleLogOut}>Logout</ListItemText>
                </ListItemButton>
              </ListItem>
              <div style={{ height: '22px', width: '22px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px 16px' }}>

                {selectedProduct > 0 &&
                  <div className=' carticonmobile'>{selectedProduct}</div>}

                <CustomDrawer
                  icon={<img src={blackCart} alt="Cart" style={{ height: '32px', width: '32px' }} />}
                  // onClick={() => handlePage('Cart')} 
                  content={
                    <div >
                      <Cart />
                    </div>
                  } />
              </div>
            </div>

          ))

        ) : (
          // authItems.map((item, index) => (
          //   <ListItem key={index} disablePadding>
          //     <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} onClick={() => handlePage(item.path)}>
          //       <ListItemText primary={item.name} />
          //     </ListItemButton>
          //   </ListItem>
          // ))
          <div className='d-flex align-items-center'>
            <div className='orderNowBurron' style={{ 'fontFamily': 'Manrope', fontWeight: '600', fontSize: '16px', cursor: 'pointer' }} onClick={() => handlePage({ path: 'SignIn' })}>
              Order Now
            </div>


          </div>
        )}

      </List>
    </Box>
  );

  const container = windowCustom !== undefined ? () => window().document.body : undefined;
  console.log("adsasd", location2.pathname + location2.search)
  return (
    <div className="navbar">
      <Box sx={{ display: 'flex' }}>
        {/* <CssBaseline /> */}
        <AppBar className={"navBarCustom"} component="nav"
          sx={{
            background: window.location.pathname == "/ourvision" ||
              window.location.pathname == "/scooters" ||
              window.location.pathname == "/bikes" ||
              window.location.pathname == "/store" ||
              window.location.pathname == "/compare" ||
              window.location.pathname == "/favourites" ||
              window.location.pathname == "/productdetails" ? '#fafafa' : 'transparent', boxShadow: 'none', color: 'black'
          }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 !important' }}>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Typography
                variant="h6"
                component="div"
                style={{ margin: "0" }}
                sx={{ flexGrow: 1 }}
              >
                <img className="customLogo" style={{ cursor: "pointer" }} src="https://metrorides.theclientdemos.com/assets/images/logo.svg" alt="Logo" onClick={handleHomePage} />
              </Typography>
            </Box>
            <Box sx={{ display: { xs: 'none', lg: 'flex' }, flexGrow: 1, justifyContent: 'start' }}>
              {navItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mx: 1,
                    gap: '10px',
                    marginRight: '40px',
                    borderBottom:  window.location.pathname === '/'+item.name.toLowerCase() ? '2px solid #000000' : '2px solid transparent',
                    transition: 'border-bottom 0.6s ease-in-out', // Smooth transition for border bottom
                  }}
                >
                  <Stack direction="horizontal" gap={0}>
                    {selectedProduct > 0 && item?.name === 'Cart' &&
                      <div className='absolute carticon'>{selectedProduct}</div>}
                    {item.icon && <img src={item.icon} alt={`${item.name} icon`} className='navIcon me-0' />}
                    <Button
                      sx={{
                        color: '#fff',
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontFamily: 'Manrope',
                      }}
                      onClick={() => handlePage(item)}
                      className='navItems'
                    >
                      {item.name}
                    </Button>
                  </Stack>

                </Box>
              ))}
            </Box>
            <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
              {localStorage.getItem("token") && !userProfile?.is_anonymous ? (
                userMe.map((item, index) => (
                  <div key={index}>
                    <div className='d-flex'>
                      <Button
                        onClick={handleClick}
                        sx={{ color: '#fff', textTransform: 'none', fontSize: '1rem' }}
                        className='navItems'
                      >
                        {/* {userMe[0].name}  */}
                        <img src={profile} style={{ height: '24px', width: '24px' }} />
                      </Button>

                      <div style={{ marginTop: '-6px', height: '22px', width: '22px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px 16px' }}>
                        {selectedProduct > 0 &&
                          <div className='absolute carticonmobile'>{selectedProduct}</div>}
                        {/* <img src={profile} style={{height:'24px', width:'24px'}} />
                  <img src={blackCart} style={{height:'32px', width:'32px'}} /> */}
                        <CustomDrawer
                          icon={<img src={blackCart} alt="Cart" style={{ height: '32px', width: '32px' }} />}
                          content={
                            <>
                              <div >
                                <Cart />
                              </div>
                            </>
                          }
                        />

                      </div>
                    </div>

                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          maxHeight: 48 * 5.5, // Adjust based on menu height
                          width: '20ch',
                        },
                      }}
                    >
                      <MenuItem 
                      style={{
                        backgroundColor: location2.pathname + location2.search === '/' ? '#e1e1e1' : 'transparent'
                      }} 
                      onClick={() => { handleClose(); navigate('/'); setTabItem(''); }}>Dashboard</MenuItem>
                      <MenuItem 
                      style={{
                        backgroundColor: location2.pathname + location2.search === '/orderlist' ? '#e1e1e1' : 'transparent'
                      }} 
                      onClick={handleOrderList}>Order List</MenuItem>
                      <MenuItem onClick={handleActiveSubscription}
                       style={{
                        backgroundColor: location2.pathname + location2.search === '/active-subscription' ? '#e1e1e1' : 'transparent'
                      }} 
                      >Active subscription</MenuItem>
                      <MenuItem onClick={handleAddress}
                       style={{
                        backgroundColor: location2.pathname + location2.search === '/manage-address' ? '#e1e1e1' : 'transparent'
                      }} 
                      >Manage Address</MenuItem>
                      <MenuItem onClick={handleFavourites}
                       style={{
                        backgroundColor: location2.pathname + location2.search === '/favourites' ? '#e1e1e1' : 'transparent'
                      }} 
                      >Favourites</MenuItem>
                      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                    </Menu>
                  </div>

                ))
              ) :
                // authItems.map((item) => (
                // <Button key={item.name} sx={{ color: '#fff', textTransform: 'none', fontSize: '1rem' }} onClick={() => handlePage(item.path)} className='navItems'>
                //   {item.name}
                // </Button>
                <div className='d-flex align-items-center'>


                  <div className='orderNowBurron' style={{ 'fontFamily': 'Manrope', fontWeight: '600', fontSize: '16px', cursor: 'pointer' }} onClick={() => handlePage({ path: 'SignIn' })}>
                    Order Now
                  </div>
                </div>
                // ))
              }

            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { lg: 'none' } }}
            >
              {/* <MenuIcon /> */}
              <AnimatedMenuIcon open={mobileOpen} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            transitionDuration={{ enter: 500, exit: 300 }}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', lg: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box', width: drawerWidth, color: 'black', padding: '0 20px'
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <Typography>
          </Typography>
        </Box>
      </Box>

    </div>
  );
}
