import React, { useState, useEffect } from "react";
import 'pages/orderList/orderList.scss';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import scooter from "assets/images/orderListScooter.png";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_HISTORY } from "store/orderHistory/constant";
import orderHistoryCallApi from "store/orderHistory/action";
import moment from 'moment';
import { Box, Checkbox, Skeleton } from "@mui/material";
import Lottie from 'react-lottie';

import emptyList from 'assets/lottie/emptyList.json'
import H1text from "components/H1text/H1text";
import { containerVariants } from "services/common";
import { motion } from "framer-motion";
import placeholderImage from 'assets/images/placeholderImage.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import orderDetailsCallApi from "store/orderDetails/action";
import { ORDER_DETAILS } from "store/orderDetails/constant";
import Ptext from "components/Ptext/Ptext";
import { ACTIVE_SUBSCRIPTIONS } from "store/activeSubscriptions/constant";
import activeSubscriptionsCallApi from "store/activeSubscriptions/action";
import BasicButton from "components/Buttons/BasicButton/BasicButton";
import CommonModal from "components/Modal/CommonModal";
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { USER_ADDRESS } from "store/userAddress/constant";
import userAddressCallApi from "store/userAddress/action";
import CustomCheckbox from "components/Inputs/CustomCheckbox/CustomCheckbox";
import countriesCallApi from "store/Countries/action";
import { COUNTRIES } from "store/Countries/constant";
import addAddressCallApi from "store/addAddress/action";
import { ADD_ADDRESS } from "store/addAddress/constant";
import editAddressCallApi from "store/editAddress/action";
import { EDIT_ADDRESS } from "store/editAddress/constant";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { DELETE_ADDRESS } from "store/deleteAddress/constant";
import deleteAddressCallApi from "store/deleteAddress/action";

const WrapperDiv = styled.div`
.special-label{
    display: none !important;
}
.phone-input {
    padding: 0 10px !important;
    border: 1px solid #dfdfdf !important;
    border-radius: 6px !important;
}
input[placeholder="Phone No"] {
          padding: 6px 12px;
    width: 100%;
    border-radius: 8px !important;
    background: transparent;
    outline: none;
}
`




const Address = () => {

    const array = [1, 2, 3, 4, 5];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activeSubscriptions = useSelector((state) => state?.activeSubscriptionsReducer?.payload?.data?.data);
    const activeSubscriptionsloader = useSelector((state) => state?.activeSubscriptionsReducer?.loading);


    const editAddressLoader = useSelector((state) => state?.editAddressReducer?.loading);
    const addAddressLoader = useSelector((state) => state?.addAddressReducer?.loading);
    const getAddressLoader = useSelector((state) => state?.userAddressReducer?.loading);
    const deleteAddressLoader = useSelector((state) => state?.deleteAddressReducer?.loading);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: emptyList,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const [isModalActive, setIsModalActive] = useState(false);
    const [title, setTitle] = useState('');
    const [country, setCountry] = useState([]);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('');
    const [countryUrl, setCountryUrl] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [postCode, setPostCode] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const handleClose = () => setIsModalActive(false);
    const userAddressData = useSelector((state) => state?.userAddressReducer?.payload?.data);
    const [buttonType, setButtonType] = useState("");
    const [editData, setEditData] = useState({});

    const titles = [
        { title: 'Mr' }, { title: 'Mrs' }, { title: 'Miss' }, { title: 'Ms' }, { title: 'Dr' }]

    useEffect(() => {
        if (editData?.title) {
            setTitle(editData.title);
        }
    }, [editData]);

    const handleTitle = (e) => {
        setTitle(e.target.value)
    };
    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    }
    const handleLastName = (e) => {
        setLastName(e.target.value)
    }
    const handlePhone = (value) => {
        setPhone(value)
    }
    const handlePostCode = (e) => {
        setPostCode(e.target.value)
    }
    const handleState = (e) => {
        setState(e.target.value)
    }
    const handleAddress = (e) => {
        setAddress(e.target.value)

    }
    const handleCountry = (e) => {
        const selectedCountryData = country.find(data => data.url === e.target.value);
        setCountryCode(selectedCountryData?.iso_3166_1_a3.substring(0, 2))

    }
    const handleCountries = () => {
        dispatch(countriesCallApi(COUNTRIES, {}, { run: true, handleFunction: (res) => handleCountriesSuccess(res) }))
    }
    const handleCountriesSuccess = (res) => {
        setCountry(res?.data)
    }
    const getUserAddress = () => {

        dispatch(userAddressCallApi(USER_ADDRESS, {}, {
            run: true,
            handleError: (res) => { toast.error(res?.status + ' ' + res?.statusText); setIsModalActive(false); },
            handleFunction: (res) => { setIsModalActive(false); }
        }))
    }

    useEffect(() => {
        getUserAddress();
        handleCountries();

    }, [])
    useEffect(() => {
        for (let i = 0; i < userAddressData?.length; i++) {
            if (userAddressData[i]?.is_default_for_shipping) {
                setSelectedAddress(userAddressData[i]);
                break; // Stop the loop once the default address is found
            }
        }
    }, [userAddressData])

    const [selectedAddress, setSelectedAddress] = useState({});
    const handleAddressSelect = (e, data) => {
        setEditData(data);
        setSelectedAddress(data);

        const body = {
            "first_name": data?.first_name,
            "last_name": data?.last_name,
            "line1": data?.line1,
            "line2": "",
            "state": data?.state,
            "phone_number": data?.phone_number,
            "country": data?.country,
            "postcode": data?.postcode,
            "title": data?.title,
            "is_default_for_shipping": true
        }
        dispatch(editAddressCallApi(EDIT_ADDRESS, body, {
            run: true,
            key: data?.id,
            // navigate: navigate,statusText
            handleError: (res) => { toast.error(res?.status + ' ' + res?.statusText); setIsModalActive(false); },

            handleFunction: (res) => { toast.success("Set as default successfully!"); getUserAddress(); }
        }))
    }

   
    const handleAddEditModel = (data, type) => {
        setButtonType(type);
        setEditData(data);
        setIsModalActive(true);
        if (type == "edit") {
            setFirstName(data?.first_name);
            setLastName(data?.last_name);
            setPhone(data?.phone_number);
            setCountryUrl(data?.country);
            setState(data?.state);
            setPostCode(data?.postcode);
            setAddress(data?.line1);
            let obj = {
                target: {
                    value: data?.country
                }
            }
            handleCountry(obj);
            setTitle(data?.title)
        } else {
            setFirstName('');
            setLastName('');
            setPhone('');
            setCountryUrl('');
            setState('');
            setPostCode('');
            setAddress('');
        }

    }

    const handleAddEdit = (type) => {
        if (!countryUrl?.length || !firstName?.length || !lastName?.length ||
            !address?.length || !phone?.length ||
            !postCode?.length || !state?.length  || !countryCode?.length) {
                // || !city?.length
            toast.error("Fill all Your data correctly");
        } else {
            if (type == 'edit') {
                //  { key: "all" }
                const body = {
                    "first_name": firstName,
                    "last_name": lastName,
                    "line1": address,
                    "line2": "",
                    "state": state,
                    "phone_number": phone,
                    "country": countryUrl,
                    "postcode": postCode,
                    "title": title,
                }
                dispatch(editAddressCallApi(EDIT_ADDRESS, body, {
                    run: true,
                    key: editData?.id,
                    // navigate: navigate,statusText
                    handleError: (res) => { 
                        if(res.status===400){
                            toast.error("The phone number entered is not valid.");
                        }else if(res.status===406){
                            toast.error("These details already exist!");
                        }
                        else{
                            toast.error(res?.status + ' ' + res?.statusText);
                        }
                          setIsModalActive(false); 
                        },

                    handleFunction: (res) => { toast.success("Address changed successfully!"); getUserAddress(); }
                }))
            } else {
                const body = {
                    "first_name": firstName,
                    "last_name": lastName,
                    "line1": address,
                    "line2": "",
                    "state": state,
                    "phone_number": phone,
                    "country": countryUrl,
                    "postcode": postCode,
                    "title": title,
                    "is_default_for_shipping": true
                }
                dispatch(addAddressCallApi(ADD_ADDRESS, body, {
                    run: true,
                    // navigate: navigate,
                    handleError: (res) => {
                        if(res.status===400){
                            toast.error("The phone number entered is not valid.");
                        }else if(res.status===406){
                            toast.error("These details already exist!");
                        }
                        else{
                            toast.error(res?.status + ' ' + res?.statusText);
                        }
                          setIsModalActive(false);
                         },
                    handleFunction: (res) => { toast.success("Address added successfully!"); getUserAddress(); }
                }))

            }
        }
    }


    const deleteAddress = (data) => {
        setEditData(data);
        // deleteAddressCallApi
        if (!data?.is_default_for_shipping) {
            dispatch(deleteAddressCallApi(DELETE_ADDRESS, {}, {
                run: true,
                key: data?.id,
                // navigate: navigate,statusText
                handleError: (res) => { toast.error(res?.status + ' ' + res?.statusText); setIsModalActive(false); },

                handleFunction: (res) => { toast.success("Address deleted successfully!"); getUserAddress(); }
            }))
        } else {
            toast.warn("The default address cannot be deleted!");
        }
    }



    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="orderList" style={{ fontFamily: "Manrope, sans-serif" }}>
            <Container className="ps-3 pe-3">
                <Row>
                    {/* <p className="orderHeading">Active Subscription</p> */}
                    <H1text size='36' lineHeight={'39.6'} fontWeight='600'>Address</H1text>
                    {/* !orderListloader  */}

                </Row>
                <Row >
                    <Col xs="12" md="6" lg="4">
                        <BasicButton
                            text={<>Add a new address</>}
                            fontWeight={'500'}
                            padding={'11px 20px'}
                            background={'#1d1d1d'}
                            color={'#ffffff'}
                            border={'1px solid #1d1d1d'}
                            style={{ marginTop: '16px' }}
                            // disabled={submitLoader}
                            onClick={() => handleAddEditModel({}, "add")}
                            height={'48px'}
                        />
                    </Col>
                </Row>
                {!getAddressLoader && <Row style={{ marginBottom: "20px" }}>
                    {userAddressData?.length > 0 &&
                        [...userAddressData].reverse().map((data, index) => (
                            <Col key={index} lg={"8"}>
                                <Box
                                    sx={{
                                        border: selectedAddress?.id === data?.id ? '2px solid #000000' : '1px solid #f0e5e5',
                                        borderRadius: '10px',
                                        padding: '20px',
                                        position: 'relative',
                                        marginTop: '18px',
                                    }}
                                >
                                    {/* Checkbox for selection */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <CustomCheckbox
                                            checked={selectedAddress?.id === data?.id}
                                            onChange={(e) => handleAddressSelect(e, data)}
                                            label={
                                                <Ptext size="14" lineHeight="20" marginTop="0">
                                                    Select Address
                                                </Ptext>
                                            }
                                        />
                                    </Box>

                                    {/* Address Details */}
                                    <Ptext size={'14'} lineHeight="16" marginTop={'0'}>
                                        {data?.first_name + ' ' + data?.last_name}
                                    </Ptext>
                                    <Ptext size={'14'} lineHeight="16">
                                        {data?.line1 + ' ' + data?.line2 + ' ' + data?.line3 + ' ' + data?.line4 + ' '}
                                    </Ptext>
                                    <Ptext size={'14'} lineHeight="16">
                                        {data?.state + ' ' + data?.postcode + ' ' + data?.country_name}
                                    </Ptext>
                                    <Ptext size={'14'} lineHeight="16">
                                        {data?.phone_number}
                                    </Ptext>

                                    {/* Edit and Delete icons */}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '0',
                                            right: '10px',
                                            marginTop: '18px',
                                            display: 'flex'
                                        }}
                                    >
                                        <EditIcon
                                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                                            onClick={() => handleAddEditModel(data, "edit")} />

                                        {/* {((deleteAddressLoader || getAddressLoader) && editData?.id === data?.id )?
                                    <CircularProgress style={{ 'color': '#000000', 'width': '22px', 'height': '22px',marginLeft: '10px', marginTop:'0' }} />
                                    : */}
                                        {!data?.is_default_for_shipping && <DeleteForeverIcon
                                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                                            onClick={() => deleteAddress(data)}
                                        />}
                                        {/* } */}
                                    </Box>
                                </Box>
                            </Col>
                        ))}
                </Row>}
                {getAddressLoader &&
                    <>
                        <Row style={{ marginBottom: "20px" }}>
                            {[...Array(2)].map((_, index) => ( // Show 3 skeletons as placeholders
                                <Col key={index} lg={"8"}>
                                    <Box
                                        sx={{
                                            border: '1px solid #f0e5e5',
                                            borderRadius: '10px',
                                            padding: '20px',
                                            position: 'relative',
                                            marginTop: '18px',
                                        }}
                                    >
                                        {/* Checkbox Skeleton */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <Skeleton variant="circular" width={24} height={24} />
                                            <Skeleton variant="text" width={100} height={16} sx={{ marginLeft: 1 }} />
                                        </Box>

                                        {/* Address Details Skeleton */}
                                        <Skeleton variant="text" width="60%" height={16} />
                                        <Skeleton variant="text" width="80%" height={16} />
                                        <Skeleton variant="text" width="70%" height={16} />
                                        <Skeleton variant="text" width="50%" height={16} />

                                        {/* Edit and Delete Icon Skeleton */}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '10px',
                                                marginTop: '18px',
                                                display: 'flex',
                                            }}
                                        >
                                            <Skeleton variant="circular" width={24} height={24} sx={{ marginLeft: '10px' }} />
                                            <Skeleton variant="circular" width={24} height={24} sx={{ marginLeft: '10px' }} />
                                        </Box>
                                    </Box>
                                </Col>
                            ))}
                        </Row>
                    </>}
                {
                    userAddressData?.length <= 0 &&
                    <Box
                        sx={{
                            margin: {
                                xs: "14px 0",
                                sm: "28px 0",
                                md: "75px 0",
                                lg: "100px 0"
                            }

                        }}
                    >
                        <Row className="" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                            <Col md={6} lg={4} >

                                <Lottie
                                    options={defaultOptions}
                                    height={400}
                                    maxWidth={400}
                                />
                                <H1text size='24' lineHeight={'28'} fontWeight='600' className="text-black " marginTop={'0'}>No Address found.</H1text>
                            </Col>
                        </Row>
                    </Box>
                }

            </Container>

            {/* modal ===   */}
            <CommonModal
                padding={'12px 40px 32px 40px'}
                width={'736px'}
                modalActive={isModalActive}
                handleClose={handleClose}
                innerContent={
                    <WrapperDiv>
                        <Container fluid>
                            {/* <form > */}
                            <Row>
                                <Col md={12} className="mt-3">
                                    {/* <Form.Select aria-label="Default select example" label="Country" onChange={handleTitle}>
                                                    {titles && titles?.length > 0 && titles.map((title, index) => (
                                                        <option >{title.title}</option>
                                                    ))}

                                                </Form.Select> */}
                                    <Form.Select
                                        aria-label="Default select example"
                                        label="title"
                                        value={title}
                                        onChange={handleTitle}
                                    >
                                        {titles && titles?.length > 0 &&
                                            titles.map((title) => (
                                                <option value={title.title} key={title.title}>
                                                    {title.title}
                                                </option>
                                            ))}
                                    </Form.Select>

                                </Col>
                                <Col md={6} className="mt-3">
                                    <input type="text" className="form-control" placeholder="First Name" value={firstName} onChange={handleFirstName} />

                                </Col>
                                <Col md={6} className="mt-3">
                                    <input type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={handleLastName} />
                                </Col>
                                <Col md={6} className="mt-3">
                                    {/* <PhoneInput
                                            className="form-control"
                                                placeholder="Phone No"
                                                value={phone}
                                                onChange={handlePhone} /> */}
                                    <PhoneInput
                                        // style={{ marginTop: '20px' }}
                                        // className="form-control phone-input "
                                        defaultCountry="US"
                                        placeholder="Phone No"
                                        value={phone}
                                        onChange={handlePhone}
                                        className="phone-input"
                                        maxLength={"15"}
                                    />
                                    {/* <PhoneInput defaultCountry={countryCode} value={phone} onChange={handlePhone} placeholder="Phone No" className="form-control" /> */}
                                </Col>
                                <Col md={6} className="mt-3">
                                    {/* <Form.Select aria-label="Default select example" label="Country" onChange={handleCountry} value={countryUrl?.url}>
                                                    <option>Country</option>
                                                    {country && country?.length > 0 && country.map((data, index) => (
                                                        <option value={data?.url} key={data.iso_3166_1_a3}>{data?.printable_name}</option>
                                                    ))}
                                                </Form.Select> */}

                                    <Form.Select
                                        aria-label="Default select example"
                                        label="Country"
                                        value={countryUrl}
                                        onChange={(e) => { handleCountry(e); setCountryUrl(e.target.value); }}
                                    >
                                        <option>Country</option>
                                        {country &&
                                            country.map((data) => (
                                                <option value={data?.url} key={data?.iso_3166_1_a3}>
                                                    {data?.printable_name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <input type="text" className="form-control" placeholder="State" value={state} onChange={handleState} />

                                </Col>
                                {/* <Col md={6} className="mt-3">
                                    <input type="text" className="form-control" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                                </Col> */}
                                <Col md={6} className="mt-3">
                                    <input type="number" className="form-control" placeholder="Postal Code" value={postCode} onChange={handlePostCode} />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <input type="text" className="form-control" placeholder="Address" onChange={handleAddress} value={address} />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Ptext size='13' lineHeight='18' fontWeight='500' marginTop='0' color='#757575'>
                                        In the US, our standard delivery is FedEx 3-5 working days, shipping out of Los Angeles, California. West coast customers typically wait 1 or 2 days, while delivery to the East coast typically takes the full 4 or 5 days.
                                    </Ptext>
                                    <Ptext size='13' lineHeight='18' fontWeight='500' marginTop='16' color='#757575'>
                                        In the UK, we offer overnight delivery with FedEx. Once we dispatch your order, we will send you tracking details
                                    </Ptext>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <BasicButton
                                        text={<>Cancel</>}
                                        fontWeight={'500'}
                                        padding={'11px 20px'}
                                        background={'#ffffff'}
                                        color={'#1d1d1d'}
                                        border={'1px solid #1d1d1d'}
                                        style={{ marginTop: '16px' }}
                                        // disabled={submitLoader}
                                        onClick={() => setIsModalActive(false)}
                                        height={'48px'}
                                    />
                                </Col>
                                <Col md={6} className="mt-3">
                                    <BasicButton
                                        text={
                                            <Box sx={{ position: 'relative' }}>
                                                Save Address
                                                {(editAddressLoader || addAddressLoader) && <CircularProgress style={{ 'color': '#ffffff', 'width': '24px', 'height': '24px', position: 'absolute', left: '115px' }} />}
                                            </Box>
                                        }
                                        fontWeight={'500'}
                                        padding={'11px 20px'}
                                        background={'#1d1d1d'}
                                        color={'#ffffff'}
                                        border={'1px solid #1d1d1d'}
                                        style={{ marginTop: '16px' }}
                                        // disabled={!editAddressLoader}
                                        onClick={() => handleAddEdit(buttonType)}
                                        height={'48px'}
                                    />
                                </Col>


                            </Row>
                            {/* </form> */}


                        </Container>
                    </WrapperDiv>
                }
            />
        </motion.div>

    )
}

export default Address;